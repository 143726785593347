/**
 * Navigation
 *
 * @selector [data-js="Navigation"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { animateProps } from 'app/util/animate';
import { easeOutCubic } from 'app/util/easings';
import { clearStyles, setStyles } from 'app/util/dom-helpers';
import { height as vpHeight } from 'app/util/viewport';
import { pubsub } from 'app/modules/pubsub';

const defaults = {
	openDuration: 330, // ms
	closeDuration: 300 // ms
};

const config = {
	optionsAttr: 'data-options',
	openTriggerRef: 'open-navigation', // Element with this ref can be anywhere in the DOM
	closeRef: 'close',
	searchInputRef: 'search-input',
	navOpenBodyClass: 'navigation-open'
};

// Suuuper ugly!! IE11 flickers when closing the nav, when removing overflow:hidden on body.
const isIE11 = window.HV.isIE11;

export default function Navigation() {
	// Private vars
	const instance = {};
	let settings = {};
	let searchInput;

	// Private methods
	const toBodyEnd = () => {
		instance.el = document.body.appendChild(instance.el);
	};

	const open = () => {
		setStyles(instance.el, {
			display: 'block',
			opacity: '0',
			zIndex: '1000'
		});

		animateProps({
			el: instance.el,
			duration: settings.openDuration,
			easing: easeOutCubic,
			props: [
				{
					propName: 'opacity',
					start: 0,
					end: 1
				}
			],
			onStart: () => {
				// Avoid showing double scrollbars on Win10
				document.body.classList.add(config.navOpenBodyClass);
				instance.setState('opening');
			},
			onComplete: () => {
				clearStyles(instance.el, ['display', 'opacity', 'zIndex']);
				instance.setState('open');
				window.addEventListener('keydown', onKeydown);
				disableBodySroll();

				pubsub.trigger('Navigation.opened');
			}
		});
	};

	const close = () => {
		if (isIE11) {
			document.body.classList.remove(config.navOpenBodyClass);
			instance.setState('closed');
			window.removeEventListener('keydown', onKeydown);
			enableBodySroll();

			return;
		}

		// Avoid showing double scrollbars on Win10
		setStyles(instance.el, { overflow: 'hidden', transform: 'translate3d(0, 0, 0)' });
		document.body.classList.remove(config.navOpenBodyClass);

		animateProps({
			el: instance.el,
			duration: settings.closeDuration,
			easing: easeOutCubic,
			props: [
				{
					propName: 'opacity',
					start: 1,
					end: 0
				}
			],
			onComplete: () => {
				instance.setState('closed');
				window.removeEventListener('keydown', onKeydown);
				enableBodySroll();
				clearStyles(instance.el, ['overflow', 'transform']);

				pubsub.trigger('Navigation.closed');
			}
		});
	};

	const highlightSearchInput = () => {
		instance.el.classList.add('dim');
	};

	const restoreHighlight = () => {
		instance.el.classList.remove('dim');
	};

	const preventDefault = e => {
		e.preventDefault();
	};

	const disableBodySroll = () => {
		//Because iOS Safari does not respect (yet) overflow:hidden correctly
		document.body.addEventListener('touchmove', preventDefault, false);
		setStyles([document.documentElement, document.body], { height: vpHeight() + 'px' });
	};

	const enableBodySroll = () => {
		document.body.removeEventListener('touchmove', preventDefault, false);
		clearStyles([document.documentElement, document.body], ['height']);
	};

	const onDocumentClick = e => {
		if (e.target.closest(`[data-ref="${config.openTriggerRef}"]`)) {
			toBodyEnd();
			open();

			e.preventDefault();
		}
	};

	const onClick = e => {
		if (e.target.closest(instance.refSelector(config.closeRef))) {
			close();
			e.preventDefault();
		}
	};

	const onKeydown = e => {
		let key = e.code || e.key;

		if (key === 'Escape') {
			close();
		}
	};

	const onSearchInputFocus = () => {
		highlightSearchInput();
	};

	const onSearchInputBlur = () => {
		restoreHighlight();
	};

	const bindEvents = () => {
		document.addEventListener('click', onDocumentClick);
		instance.el.addEventListener('click', onClick);
		searchInput.addEventListener('focus', onSearchInputFocus);
		searchInput.addEventListener('blur', onSearchInputBlur);
	};

	const unbindEvents = () => {
		document.removeEventListener('click', onDocumentClick);
		instance.el.removeEventListener('click', onClick);
		searchInput.removeEventListener('focus', onSearchInputFocus);
		searchInput.removeEventListener('blur', onSearchInputBlur);
	};

	// Public vars
	instance.ns = 'Navigation';

	// Public methods
	instance.init = element => {
		instance.el = element;

		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);

		searchInput = instance.ref(config.searchInputRef);

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
