export const setStyles = (elements, styles) => {
	if (elements instanceof HTMLElement) {
		elements = [elements];
	}

	const set = (el, stylesObj) => {
		for (let p in stylesObj) {
			if (stylesObj.hasOwnProperty(p)) {
				el.style[p] = stylesObj[p];
			}
		}
	};

	for (let i = 0; i < elements.length; i++) {
		set(elements[i], styles);
	}
};

export const clearStyles = (elements, styles) => {
	if (elements instanceof HTMLElement) {
		elements = [elements];
	}

	const set = (el, stylesArr) => {
		for (let i = 0; i < stylesArr.length; i++) {
			el.style[stylesArr[i]] = '';
		}
	};

	for (let i = 0; i < elements.length; i++) {
		set(elements[i], styles);
	}
};
