/**
 * Peeker
 *
 * @selector [data-js="Peeker"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { resizeeventsManager, scrolleventsManager } from 'app/util/events-manager';
import { pointerdownEvent, pointerupEvent, pointermoveEvent } from 'app/util/pointer-event-names';
import { animateProps } from 'app/util/animate';
import { easeOutCubic } from 'app/util/easings';
import { clearStyles, setStyles } from 'app/util/dom-helpers';
import { pubsub } from 'app/modules/pubsub';

const defaults = {
	canvasWidth: 2000,
	clipRadius: 30, // percentage of width
	cursorPadding: 0.07,
	cursorDelay: 80,
	mobileYOffset: 70
};

const config = {
	optionsAttr: 'data-options',
	insideRef: 'insideImg',
	bgImageRef: 'image',
	cursorRef: 'cursor',
	peekingBodyClass: 'peeking'
};

export default function Peeker() {
	// Private vars
	const instance = {};
	let settings = {};
	let insideImg;
	let bgImage;
	let cursor;
	let cursorRadius;
	let pointerX;
	let pointerY;
	let time;
	let movements = [];

	let rect;
	let isAnimatingInOut = false;
	let peekerVisible = false;
	let forceHidden = false;
	let yOffset = 0;
	let insideImgLoaded = false;
	let bgImageLoaded = false;

	// Private methods
	const runAnimation = ts => {
		if (movements.length && movements[0].time <= ts) {
			setStyles(cursor, {
				transform: `translate(${movements[0].x - cursorRadius}px, ${movements[0].y - cursorRadius}px)`
			});
			setStyles(insideImg, {
				transform: `translate(${-movements[0].x + cursorRadius}px, ${-movements[0].y + cursorRadius}px)`
			});

			movements.shift();
		}

		if (movements.length) {
			window.requestAnimationFrame(runAnimation);
		}
	};

	const addMovement = ({ x, y, time }) => {
		movements.push({
			x,
			y,
			time
		});

		window.requestAnimationFrame(runAnimation);
	};

	const loadImages = () => {
		const onload = () => {
			if (!bgImageLoaded || !insideImgLoaded) {
				return;
			}

			onResize();
			bindEvents();
		};

		if (insideImg.complete && bgImage.complete) {
			bgImageLoaded = insideImgLoaded = true;
			onload();
		} else {
			insideImg.onload = () => {
				insideImgLoaded = true;
				onload();
			};

			bgImage.onload = () => {
				bgImageLoaded = true;
				onload();
			};
		}
	};

	const animOut = () => {
		animateProps({
			el: cursor,
			duration: 200,
			easing: easeOutCubic,
			props: [
				{
					propName: 'opacity',
					start: 1,
					end: 0
				}
			],
			onStart: () => {
				isAnimatingInOut = true;
				cursor.setAttribute('data-state', 'anim-out');
			},
			onComplete: () => {
				isAnimatingInOut = false;
				peekerVisible = false;
				cursor.setAttribute('data-state', '');
			}
		});
	};

	const animIn = () => {
		setStyles(cursor, { willChange: 'opacity transform' });

		// Pre-position cursor. Avoids short flickering at beginning when moving cursor
		setStyles(cursor, { transform: `translate(${pointerX - cursorRadius}px, ${pointerY - cursorRadius}px)` });
		setStyles(insideImg, { transform: `translate(${-pointerX + cursorRadius}px, ${-pointerY + cursorRadius}px)` });

		animateProps({
			el: cursor,
			duration: 300,
			delay: 60,
			easing: easeOutCubic,
			props: [
				{
					propName: 'opacity',
					start: 0,
					end: 1
				}
			],
			onStart: () => {
				isAnimatingInOut = true;
				cursor.setAttribute('data-state', 'anim-in');
			},
			onComplete: () => {
				isAnimatingInOut = false;
				peekerVisible = true;
				cursor.setAttribute('data-state', 'visible');
				clearStyles(cursor, ['opacity', 'willChange']);
			}
		});
	};

	const translateXY = () => {
		pointerX -= rect.left;
		pointerY -= rect.top + yOffset;
	};

	const inBounds = (x, y) => {
		return (
			x >= rect.left + settings.cursorPadding * (rect.right - rect.left) &&
			x <= rect.right - settings.cursorPadding * (rect.right - rect.left) &&
			y >= rect.top + settings.cursorPadding * (rect.right - rect.left) &&
			y <= rect.bottom - settings.cursorPadding * (rect.right - rect.left)
		);
	};

	const onPointermove = e => {
		pointerX = e.clientX !== undefined ? e.clientX : e.targetTouches[0].clientX;
		pointerY = e.clientY !== undefined ? e.clientY : e.targetTouches[0].clientY;

		if (!inBounds(pointerX, pointerY - yOffset)) {
			// Cursor out of bounds
			if (peekerVisible && !isAnimatingInOut) {
				translateXY();
				animOut();
			}

			document.body.classList.remove(config.peekingBodyClass);

			return;
		}

		if (!peekerVisible && !isAnimatingInOut && !forceHidden) {
			document.body.classList.add(config.peekingBodyClass);

			animIn();
		}

		e.preventDefault();

		time = window.performance.now();
		translateXY();

		// Record movement
		addMovement({
			x: pointerX,
			y: pointerY,
			time: time + settings.cursorDelay
		});
	};

	const onResize = () => {
		rect = instance.el.getBoundingClientRect();
		setStyles(insideImg, { width: rect.width + 'px' });
		cursorRadius = cursor.getBoundingClientRect().width / 2;
	};

	const onPointerdown = e => {
		if (document.documentElement.classList.contains('touch')) {
			pointerX = e.clientX !== undefined ? e.clientX : e.targetTouches[0].clientX;
			pointerY = e.clientY !== undefined ? e.clientY : e.targetTouches[0].clientY;

			yOffset = settings.mobileYOffset;

			if (!inBounds(pointerX, pointerY)) {
				return;
			}

			e.preventDefault();

			translateXY();

			document.addEventListener(pointerupEvent, onPointerup);

			if (!peekerVisible && !isAnimatingInOut) {
				animIn();
			}
		}
	};

	const onPointerup = () => {
		if (document.documentElement.classList.contains('touch')) {
			document.removeEventListener(pointerupEvent, onPointerup);

			if (peekerVisible && !isAnimatingInOut) {
				animOut();
			}
		}
	};

	const onNavigationOpened = () => {
		forceHidden = true;
	};
	const onNavigationClosed = () => {
		forceHidden = false;
	};

	const bindEvents = () => {
		document.addEventListener(pointermoveEvent, onPointermove, { passive: false });
		document.addEventListener(pointerdownEvent, onPointerdown);
		resizeeventsManager.add(onResize);
		scrolleventsManager.add(onResize);

		pubsub.on('Navigation.opened', onNavigationOpened);
		pubsub.on('Navigation.closed', onNavigationClosed);
	};

	const unbindEvents = () => {
		document.removeEventListener(pointermoveEvent, onPointermove);
		document.removeEventListener(pointerdownEvent, onPointerdown);
		resizeeventsManager.remove(onResize);
		scrolleventsManager.remove(onResize);

		pubsub.off('Navigation.opened', onNavigationOpened);
		pubsub.off('Navigation.closed', onNavigationClosed);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		// For android chrome
		setStyles(instance.el, { touchAction: 'none' });

		settings = Object.assign({}, defaults, options);
		bgImage = instance.ref(config.bgImageRef);
		insideImg = instance.ref(config.insideRef);
		cursor = instance.ref(config.cursorRef);

		loadImages();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
