/**
 * VideoSection
 *
 * @selector [data-js="VideoSection"]
 * @enabled true
 */
import { base } from 'app/util/base';

export default function VideoSection() {
	// Private vars
	const instance = {};
    let playBtn;
    let pauseBtn;
    let videoEl;

	const isMobile = window.matchMedia('(max-width: 800px)');

	// Private methods

	const initVideo = e => {
		const d = videoEl.dataset;
		videoEl.src = d[e.matches ? 'mobile' : 'desktop'];
		videoEl.poster = d[e.matches ? 'postermobile' : 'posterdesktop'];
	};

	const onPlayBtn = () => {
		instance.el.classList.add('is-playing');
		videoEl.play();

        if (videoEl.muted) {
            videoEl.currentTime = 0;
            videoEl.muted = false;
        }
	};

	const onPauseBtn = () => {
		instance.el.classList.remove('is-playing');
		videoEl.pause();
        videoEl.muted = true;
	};

	const bindEvents = () => {
		playBtn.addEventListener('click', onPlayBtn);
		pauseBtn.addEventListener('click', onPauseBtn);
		isMobile.addEventListener('change', initVideo);
	};

	const unbindEvents = () => {
		playBtn.removeEventListener('click', onPlayBtn);
		pauseBtn.removeEventListener('click', onPauseBtn);
		isMobile.removeEventListener('change', initVideo);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

        playBtn = instance.ref('playbutton');
        pauseBtn = instance.ref('pausebutton');
        videoEl = instance.ref('video');

		initVideo(isMobile);
		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
