/**
 * ContentDrawer
 *
 * @selector [data-js="ContentDrawer"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { pubsub } from 'app/modules/pubsub';

export default function ContentDrawer() {
	// Private vars
	const instance = {};
	let closeElements, openElements;
	let id;

	function open() {
		document.body.classList.add('has-drawer');
		instance.el.classList.add('is-opened');
		pubsub.trigger('Navigation.opened');
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 300);
	}

	function close() {
		document.body.classList.remove('has-drawer');
		instance.el.classList.remove('is-opened');
		pubsub.trigger('Navigation.closed');
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 300);
	}

	function onClickOutside(e) {
		if (e.target === instance.el) {
			close();
		}
	}

	const reInit = () => {
		registerHandels();
		bindEvents();

		if (instance.el.querySelectorAll('.ProductSlider').length > 0) {
			instance.el.classList.add('has-ProductSlider');
		} else {
			instance.el.classList.remove('has-ProductSlider');
		}
	};

	const registerHandels = () => {
		openElements = document.body.querySelectorAll(`[data-ref="${id}"]`);
		closeElements = instance.el.querySelectorAll('.ContentDrawer--close');
	};

	const bindEvents = () => {
		instance.el.addEventListener('click', onClickOutside);
		openElements.forEach(d => d.addEventListener('click', open));
		closeElements.forEach(d => d.addEventListener('click', close));
		document.addEventListener(`opendrawer:${id}`, open);
		document.addEventListener(`closedrawer:${id}`, close);
		document.addEventListener(`closedrawer:all`, close);

		document.addEventListener(`ContentDrawer:reInit`, reInit);
	};

	const unbindEvents = () => {
		instance.el.removeEventListener('click', onClickOutside);
		openElements.forEach(d => d.removeEventListener('click', open));
		closeElements.forEach(d => d.removeEventListener('click', close));
		document.removeEventListener(`opendrawer:${id}`, open);
		document.removeEventListener(`closedrawer:${id}`, close);
		document.removeEventListener(`closedrawer:all`, close);

		document.removeEventListener(`ContentDrawer:reInit`, reInit);
	};

	// Public vars
	instance.ns = 'ContentDrawer';

	// Public methods
	instance.init = element => {
		instance.el = element;
		id = element.id;
		Object.assign(instance, base(instance));

		reInit();

		if (id === window.location.hash.substring(1)) {
			open();
		}

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
