/**
 * ModuleName
 *
 * @selector [data-js="DealerMap"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { width as vpWidth } from 'app/util/viewport';
import MarkerClusterer from 'vendor/markerclusterer';
import { pubsub } from 'app/modules/pubsub';
import { str2dom } from 'app/util/str2dom';

const defaults = {
	minSearchRadius: 2000, // in meters
	maxSearchRadius: 50000, // meters
	searchRadiusIncrement: 2000, // meters
	markerImg: '',
	markerImgActive: '',
	markerClusterImg1: '',
	markerClusterImg2: '',
	markerClusterImg3: '',
	markerClusterImg4: '',
	markerClusterImg5: '',
	visibleOnBreakpoint: 750,
	staticTexts: {
		email: 'Email',
		website: 'Website',
		availability: 'Availablity'
	},
	popupTpl: ({ name, address, phone, email, website, categories, staticTexts }) => `
<div class="popup-bubble">
	<div class="DealerPopup">
		<p class="DealerPopup--title title-xs">${name}</p>

		<div class="DealerPopup--address body-light">
			${address}
			${phone ? '<p>' + phone + '</p>' : ''}
		</div>


		<div class="DealerPopup--contact">
			${email ? '<span class="email"><a href="mailto:' + email + '">' + staticTexts.email + '</a></span>' : ''}
			${
				website
					? '<span class="website"><a href="' +
					  website +
					  '" target="_blank" rel="noreferrer noopener">' +
					  staticTexts.website +
					  '</a></span>'
					: ''
			}
		</div>

		<p class="DealerPopup--availability">${staticTexts.availability}</p>
		<p class="body-light">${categories.join(', ')}</p>
	</div>
</div>
`
};

const config = {
	optionsAttr: 'data-options',
	mapRef: 'map'
};

// https://stackoverflow.com/a/1502821
const getDistance = (p1, p2) => {
	const rad = x => (x * Math.PI) / 180;

	let R = 6378137; // Earth’s mean radius in meter
	let dLat = rad(p2.lat() - p1.lat());
	let dLong = rad(p2.lng() - p1.lng());
	let a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
	let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	return R * c; // returns the distance in meter
};

export default function DealerMap() {
	// Private vars
	const instance = {};
	let settings = {};
	let mapContainer;
	let map;
	let geocoder;
	let markers = [];
	let markerClusterer;
	let filter = {
		locationOrZip: '',
		text: '',
		category: 'all'
	};
	let markersData;
	let categories;
	let openMarkerId = -1;
	let currentPopup;
	let currentMarker;
	let searchRadius;
	let foundLocation;
	let foundBound;
	let iconInactive;
	let iconInactiveCs;
	let iconActive;

	const Popup = function(position, content) {
		this.position = position;

		// This zero-height div is positioned at the bottom of the bubble.
		let bubbleAnchor = document.createElement('div');
		bubbleAnchor.classList.add('popup-bubble-anchor');
		bubbleAnchor.appendChild(content);

		// This zero-height div is positioned at the bottom of the tip.
		this.containerDiv = document.createElement('div');
		this.containerDiv.classList.add('popup-container');
		this.containerDiv.appendChild(bubbleAnchor);

		window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
	};

	// Private methods
	const clearMarkers = () => {
		markerClusterer && markerClusterer.clearMarkers();

		for (let i = markers.length; i--; ) {
			markers[i].setMap(null);
		}

		markers = [];
	};

	const closePopup = () => {
		currentPopup.setMap(null);
		openMarkerId = -1;
		currentMarker.setIcon(currentMarker.circlesleep ? iconInactiveCs : iconInactive);
	};

	const onMarkerClick = (markerData, marker) => {
		return e => {
			if (markerData.id === openMarkerId) {
				closePopup();
			} else {
				if (openMarkerId > -1) {
					// There's already an open popup. close it first
					closePopup();
				}

				marker.setIcon(iconActive);

				// open popup
				currentPopup = new Popup(
					e.latLng,
					str2dom(
						settings.popupTpl({
							name: markerData.name,
							address: markerData.address,
							phone: markerData.phone,
							email: markerData.email,
							website: markerData.website,
							categories: markerData.categories.map(c => categories.filter(cat => cat.id === c)[0].name),
							staticTexts: settings.staticTexts
						})
					)
				);

				openMarkerId = markerData.id;
				currentPopup.setMap(map);
				currentMarker = marker;

				map.panTo(marker.getPosition());
				map.panBy(0, -200);
			}
		};
	};

	const setMarkers = () => {
		// Only filter markers by category
		for (let i = markersData.length; i--; ) {
			let hasCategory =
				filter.category === 'all' ? true : markersData[i].categories.indexOf(filter.category) > -1;
			let hasText =
				filter.text === '' ? true : (markersData[i].name.toLowerCase()).indexOf(filter.text.toLowerCase()) > -1;

			if (hasCategory && hasText) {
				let marker = new window.google.maps.Marker({
					position: { lat: Number(markersData[i].lat), lng: Number(markersData[i].lng) },
					map: map,
					title: markersData[i].name,
					icon: iconInactive
				});

				marker.addListener('click', onMarkerClick(markersData[i], marker));

				// Custom props
				marker.dealerId = markersData[i].id;

				// set circle sleep flag & icon
				if (markersData[i].categories.indexOf(categories[3].id) > -1) {
					marker.circlesleep = true;
					marker.icon = iconInactiveCs;
				}

				markers.push(marker);
			}
		}
	};

	const setMarkerClusterer = () => {
		markerClusterer = new MarkerClusterer(map, markers, {
			zoomOnClick: true,
			gridSize: 30,
			maxZoom: 10,
			styles: [
				// {
				// 	url: settings.markerClusterImg1,
				// 	width: 32,
				// 	height: 32,
				// 	fontFamily: 'Comic sans'
				// },
				{
					url: settings.markerClusterImg2,
					width: 42,
					height: 42,
					fontFamily: 'Work Sans, Arial',
					fontWeight: '300',
					textSize: 14,
					textColor: '#0F0F0E'
				},
				// {
				// 	url: settings.markerClusterImg3,
				// 	width: 64,
				// 	height: 64,
				// 	fontFamily: 'Comic sans'
				// },
				{
					url: settings.markerClusterImg4,
					width: 82,
					height: 82,
					fontFamily: 'Work Sans, Arial',
					fontWeight: '300',
					textSize: 14,
					textColor: '#0F0F0E'
				},
				{
					url: settings.markerClusterImg5,
					width: 108,
					height: 108,
					fontFamily: 'Work Sans, Arial',
					fontWeight: '300',
					textSize: 14,
					textColor: '#0F0F0E'
				}
			]
		});
	};

	const getMarkersInBound = () => {
		let arr = [];
		let mapBounds = map.getBounds();

		for (let i = markersData.length; i--; ) {
			if (mapBounds.contains(markersData[i].latLng)) {
				arr.push(markersData[i]);
			}
		}

		return arr;
	};

	const fitToMarkers = markers => {
		let bounds = foundBound || new window.google.maps.LatLngBounds();
		for (let i = markers.length; i--; ) {
			bounds.extend(markers[i].latLng);
		}
		if (foundBound) {
			foundBound = null;
		}

		map.fitBounds(bounds, {
			top: 50,
			left: 50,
			right: 50,
			bottom: 50
		});
	};

	const searchMarkers = () => {
		let isMobile = vpWidth() <= settings.visibleOnBreakpoint;
		let resultMarkers = [];

		if (openMarkerId > -1) {
			closePopup();
		}

		const applyDistances = (markersArray, rootLocation) => {
			for (let i = markersArray.length; i--; ) {
				markersArray[i].distance = Math.round(getDistance(rootLocation, markersArray[i].latLng));
			}
		};

		const search = resultLocation => {
			let tmpDistance;

			for (let i = markersData.length; i--; ) {
				tmpDistance = getDistance(resultLocation, markersData[i].latLng);
				tmpDistance = Math.round(tmpDistance);

				if (tmpDistance <= searchRadius) {
					resultMarkers.push(markersData[i]);
				}
			}

			if (resultMarkers.length) {
				// We found some markers.
				// Now update map bounds to see if we have even more markers in viewport.
				!isMobile && fitToMarkers(resultMarkers);

				// Update markers result
				!isMobile && (resultMarkers = getMarkersInBound());

				applyDistances(resultMarkers, resultLocation);

				// Apply category to result
				if (filter.category !== 'all' || filter.text !== '') {

					if (filter.category !== 'all') {
						resultMarkers = resultMarkers.filter(r => r.categories.indexOf(filter.category) > -1);
					}
					if (filter.text !== '') {
						resultMarkers = resultMarkers.filter(
							r =>
								r.name
									.toLowerCase()
									.indexOf(
										filter.text.toLowerCase()
									) > -1
						);
					}

					// if after applying category there are no results --> show not found overlay
					if (!resultMarkers.length) {
						searchRadius += settings.searchRadiusIncrement;

						if (settings.maxSearchRadius < searchRadius) {
							searchMarkers();
						} else {
							instance.setState('noresult');
							pubsub.trigger('DealerMap.search', {
								sortByDistance: true,
								dealers: []
							});

							foundLocation = null;
						}

						return;
					}
				}

				// Set markers according to category
				if (!isMobile) {
					clearMarkers();
					setMarkers();
					setMarkerClusterer();
				}

				// Successfull search
				foundLocation = null;
				instance.setState('');

				pubsub.trigger('DealerMap.search', {
					sortByDistance: true,
					dealers: resultMarkers.map(r => ({
						id: r.id,
						distance: r.distance
					}))
				});
			} else {
				// expand search
				searchRadius += settings.searchRadiusIncrement;
				if (settings.maxSearchRadius < searchRadius) {
					searchMarkers();
				}
			}
		};

		// Filter has been reset. Show all.
		if (!filter.locationOrZip && filter.category === 'all' && filter.text === '') {
			if (!isMobile) {
				clearMarkers();
				setMarkers();
				setMarkerClusterer();
			}

			if (!isMobile) {
				if (map.getBounds()) {
					fitToMarkers(markersData);

					pubsub.trigger('DealerMap.search', {
						sortByDistance: false,
						dealers: markersData.map(r => ({ id: r.id }))
					});
				}
			}

			if (isMobile) {
				pubsub.trigger('DealerMap.search', {
					sortByDistance: false,
					dealers: markersData.map(r => ({ id: r.id }))
				});
			}

			return;
		}

		// Search by location. And can be filtered by category too.
		if (filter.locationOrZip) {
			if (foundLocation) {
				search(foundLocation);
			} else {
				geocoder.geocode(
					{
						address: filter.locationOrZip,
						componentRestrictions: {
							country: 'CH' // Only search in Switzerland
						}
					},
					function(results, status) {
						if (status === window.google.maps.GeocoderStatus.OK) {
							foundLocation = results[0].geometry.location;
							foundBound = results[0].geometry.viewport;
							search(foundLocation, results[0].geometry.viewport);
						} else {
							console.error('Error: ' + status.toString());
							pubsub.trigger('DealerMap.notFound');
						}
					}
				);
			}

			return;
		}

		// Filter only by category
		if (filter.category !== 'all' || filter.text !== '') {
			if (!isMobile) {
				clearMarkers();
				setMarkers();
				setMarkerClusterer();
			}
			if (filter.category !== 'all') {
				resultMarkers = markersData.filter(
					r => r.categories.indexOf(filter.category) > -1
				);
			}
			if (filter.text !== '') {
				resultMarkers = markersData.filter(
					r => r.name.toLowerCase().indexOf(filter.text.toLowerCase()) > -1
				);
			}

			if (!isMobile) {
				if (map.getBounds()) {
					fitToMarkers(resultMarkers);

					pubsub.trigger('DealerMap.search', {
						sortByDistance: false,
						dealers: resultMarkers.map(r => ({ id: r.id }))
					});
				}
			}

			if (isMobile) {
				pubsub.trigger('DealerMap.search', {
					sortByDistance: false,
					dealers: resultMarkers.map(r => ({ id: r.id }))
				});
			}
		}
	};

	const initMap = () => {
		map = new window.google.maps.Map(mapContainer, {
			center: { lat: 46.7230738, lng: 8.4545935 },
			zoom: 8,
			maxZoom: 18,
			mapTypeControl: false,
			streetViewControl: false,
			fullscreenControl: false,
			styles: JSON.parse(instance.el.getAttribute('data-map-style')),
			scrollwheel: false
		});

		map.addListener('click', () => {
			if (openMarkerId > -1) {
				closePopup();
			}
		});

		let idleListener = map.addListener('idle', () => {
			pubsub.trigger('DealerMap.idle');
			window.google.maps.event.removeListener(idleListener);
		});

		// Set up prototype for Popup
		Popup.prototype = Object.create(window.google.maps.OverlayView.prototype);
		Popup.prototype.onAdd = function() {
			this.getPanes().floatPane.appendChild(this.containerDiv);
		};
		Popup.prototype.onRemove = function() {
			if (this.containerDiv.parentElement) {
				this.containerDiv.parentElement.removeChild(this.containerDiv);
			}
		};
		Popup.prototype.draw = function() {
			var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

			// Hide the popup when it is far out of view.
			var display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

			if (display === 'block') {
				this.containerDiv.style.left = divPosition.x + 'px';
				this.containerDiv.style.top = divPosition.y + 'px';
			}
			if (this.containerDiv.style.display !== display) {
				this.containerDiv.style.display = display;
			}
		};

		iconInactive = {
			url: settings.markerImg,
			size: new window.google.maps.Size(20, 20),
			scaledSize: new window.google.maps.Size(20, 20),
			anchor: new window.google.maps.Point(10, 10)
		};

		iconInactiveCs = {
			url: settings.markerImgCs,
			size: new window.google.maps.Size(20, 20),
			scaledSize: new window.google.maps.Size(20, 20),
			anchor: new window.google.maps.Point(10, 10)
		};

		iconActive = {
			url: settings.markerImgActive,
			size: new window.google.maps.Size(20, 20),
			scaledSize: new window.google.maps.Size(20, 20),
			anchor: new window.google.maps.Point(10, 10)
		};

		// Apply initial filter
		searchRadius = settings.minSearchRadius;
		searchMarkers();
	};

	const checkApiReady = () => {
		if (
			document.documentElement.classList.contains('maps-api-ready') &&
			instance.el.hasAttribute('data-map-style')
		) {
			bindEvents();

			markersData = markersData.map(m => {
				m.latLng = new window.google.maps.LatLng(m.lat, m.lng);
				return m;
			});

			geocoder = new window.google.maps.Geocoder();

			if (vpWidth() >= settings.visibleOnBreakpoint) {
				initMap();
			} else {
				pubsub.trigger('DealerMap.idle');
			}
		} else {
			requestAnimationFrame(checkApiReady);
		}
	};

	const onFilter = data => {
		filter = {
			...data
		};

		searchRadius = settings.minSearchRadius;

		searchMarkers();
	};

	const bindEvents = () => {
		pubsub.on('DealerFilter.filter', onFilter);
	};

	const unbindEvents = () => {
		pubsub.off('DealerFilter.filter', onFilter);
	};

	const prepareData = () => {
		for (let i = markersData.length; i--; ) {
			markersData[i].categories = markersData[i].categories.map(c => Number(c));
		}

		categories = categories.map(c => ({ name: c.name, id: Number(c.id) }));
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);

		mapContainer = instance.ref(config.mapRef);
		markersData = JSON.parse(instance.el.getAttribute('data-dealers'));
		categories = JSON.parse(instance.el.getAttribute('data-dealer-categories'));

		prepareData();

		checkApiReady();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
