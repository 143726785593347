window.addEventListener('CookiebotOnDialogDisplay', () => {
    const container = document.querySelector('[data-cookiebot=container]');
    const showDetailsButton = container.querySelector('[data-cookiebot=show-details]');
    const hideDetailsButton = container.querySelector('[data-cookiebot=hide-details]');
    const onlyEssentialButton = container.querySelector('[data-cookiebot=only-essential]');
    const selectAllCheckbox = container.querySelector('[data-cookiebot=select-all]');
    const checkboxes = container.querySelectorAll('input[id*=CybotCookiebotDialogBodyLevelButton]:not([disabled])');

    container.removeAttribute('style');

    const state = new Proxy({
        bannerVisible: false,
        detailsVisible: false
    }, {
        set: (state, property, value) => {
            if (property === 'bannerVisible') {
                window.requestAnimationFrame(() => {
                    container.classList.toggle('banner-visible', value);
                });
            }

            if (property === 'detailsVisible') {
                window.requestAnimationFrame(() => {
                    container.classList.toggle('details-visible', value);
                    document.body.classList.toggle('scroll-block-vertical', value);
                });
            }

            return Reflect.set(state, property, value);
        }
    });

    const showBanner = () => {
        state.bannerVisible = true;
    };

    const showDetails = () => {
        state.detailsVisible = true;
    }

    const hideDetails = () => {
        state.detailsVisible = false;
    }

    const hideBanner = () => {
        state.detailsVisible = false;
        state.bannerVisible = false;

        window.setTimeout(() => {
            container.remove();
        }, 500)
    }

    const handleCheckboxChange = ({ target }) => {
        if (target.isSameNode(selectAllCheckbox)) {
            for (const checkbox of checkboxes) {
                checkbox.checked = selectAllCheckbox.checked;
            }
        } else {
            selectAllCheckbox.checked = Array.from(checkboxes).every(checkbox => checkbox.checked);
        }
    };

    const consentEssential = () => {
        window.Cookiebot.submitCustomConsent(false, false, false);
    };

    onlyEssentialButton.addEventListener('click', consentEssential);
    showDetailsButton.addEventListener('click', showDetails);
    hideDetailsButton.addEventListener('click', hideDetails);
    container.addEventListener('change', handleCheckboxChange);

    window.addEventListener('CookiebotOnAccept', hideBanner, { once: true });
    window.addEventListener('CookiebotOnDecline', hideBanner, { once: true  });

    window.setTimeout(showBanner, 250);
});

window.addEventListener('CookiebotOnLoad', () => {
    const renewButton = document.querySelector('[href="#cookiebot"]');

    if (renewButton && window.Cookiebot) {
        const callCookiebotRenew = (e) => {
            window.Cookiebot.renew();
            e.preventDefault();
        }

        renewButton.addEventListener('click', callCookiebotRenew);
    }
});
