const touchClass = 'touch';

const removeListeners = () => {
	document.removeEventListener('mousemove', onMousemove);
	document.removeEventListener('pointermove', onPointermove);
};

const onMousemove = () => {
	document.documentElement.classList.remove(touchClass);

	removeListeners();
};

const onPointermove = e => {
	if (e.pointerType === 'mouse') {
		document.documentElement.classList.remove(touchClass);
	}

	removeListeners();
};

// By default we assume that we're on a touch device
document.documentElement.classList.add(touchClass);

document.addEventListener('pointermove', onPointermove);

if (!('ontouchstart' in window)) {
	document.addEventListener('mousemove', onMousemove);
}
