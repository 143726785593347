/**
 * Accordion
 *
 * @selector [data-js="Accordion"]
 * @enabled true
 */
import { clearStyles, setStyles } from 'app/util/dom-helpers';
import { animateProps } from 'app/util/animate';
import { easeOutCubic } from 'app/util/easings';
import { base } from 'app/util/base';

/**
 * Options for initialState:
 * - 'none' (all items collapsed)
 * - 'all' (all items expanded. Only works when openMode:'multiple'. Else it behaves like 'first')
 * - 'first' (first item expanded)
 */
const initialStates = {
	NONE: 'collapsed',
	ALL: 'expanded',
	FIRST: 'first'
};

/**
 * Options for openMode:
 * - 'single' (only one item can be expanded)
 * - 'multiple' (several items can be expanded)
 */
const openModes = {
	SINGLE: 'single',
	MULTIPLE: 'multiple'
};

const config = {
	optionsAttr: 'data-options',
	triggerClass: '.js-accordion-section-trigger',
	contentClass: '.js-accordion-content',
	triggerRef: 'trigger',
	contentRef: 'content'
};

const defaults = {
	initialState: initialStates.NONE,
	openMode: openModes.MULTIPLE
};

export default function Accordion() {
	// Private vars
	const instance = {};
	let settings = {};
	let triggers;
	let contents;

	// Private methods
	const setInitialState = () => {
		switch (settings.initialState) {
			case initialStates.NONE:
				// Do nothing. All elements are collapsed by default.
				break;

			case initialStates.ALL:
				if (settings.openMode === openModes.MULTIPLE) {
					for (let i = triggers.length; i--; ) {
						openSection({
							trigger: triggers[i],
							content: contents[i],
							useAnimation: false
						});
					}
				}

				break;
			case initialStates.FIRST:
			default:
				openSection({
					trigger: triggers[0],
					content: contents[0],
					useAnimation: false
				});
		}
	};

	const decorateSections = () => {
		for (let i = 0; i < triggers.length; i++) {
			triggers[i].setAttribute('data-index', i);
			contents[i].setAttribute('data-index', i);
		}
	};

	const setHeight = content => {
		let height;

		content.style.display = 'block';
		height = content.offsetHeight;
		content.setAttribute('data-height', height);
		content.style.display = '';

		return height;
	};

	const openSection = ({ trigger, content, useAnimation }) => {
		useAnimation = useAnimation === undefined ? true : useAnimation;

		const open = () => {
			if (useAnimation) {
				unbindEvents();
				const height = setHeight(content);

				// Prepare for opening transition
				trigger.setAttribute('data-state', 'opening');
				content.setAttribute('data-state', 'opening');

				setStyles(content, {
					height: '0',
					display: 'block',
					overflow: 'hidden',
					willChange: 'height'
				});

				// Start transition
				animateProps({
					el: content,
					duration: 240,
					props: [
						{
							propName: 'height',
							start: 0,
							end: Number(height),
							suffix: 'px'
						}
					],
					easing: easeOutCubic,
					onComplete: () => {
						trigger.setAttribute('data-state', 'open');
						content.setAttribute('data-state', 'open');

						clearStyles(content, ['height', 'display', 'overflow', 'willChange']);

						bindEvents();
					}
				});
			} else {
				trigger.setAttribute('data-state', 'open');
				content.setAttribute('data-state', 'open');
			}
		};

		if (settings.openMode === openModes.SINGLE) {
			// Only one section can be opened. Close it.
			for (let i = triggers.length; i--; ) {
				if (triggers[i].getAttribute('data-state') === 'open') {
					closeSection({
						trigger: triggers[i],
						content: contents[i],
						useAnimation: true,
						callback: open
					});
					return;
				}
			}

			// No section is open. nothing to close.
			open();
		} else {
			open();
		}
	};

	const closeSection = ({ trigger, content, useAnimation, callback }) => {
		let contentHeight;

		useAnimation = useAnimation === undefined ? true : useAnimation;

		if (useAnimation) {
			unbindEvents();

			// Prepare for closing transition
			contentHeight = content.offsetHeight;

			setStyles(content, {
				display: 'block',
				overflow: 'hidden',
				height: content.offsetHeight + 'px',
				willChange: 'height'
			});

			trigger.setAttribute('data-state', 'closing');
			content.setAttribute('data-state', 'closing');

			// Start transition
			animateProps({
				el: content,
				duration: 240,
				props: [
					{
						propName: 'height',
						start: contentHeight,
						end: 0,
						suffix: 'px'
					}
				],
				easing: easeOutCubic,
				onComplete: () => {
					// Clean up
					clearStyles(content, ['overflow', 'height', 'display', 'willChange']);

					trigger.setAttribute('data-state', 'closed');
					content.setAttribute('data-state', 'closed');

					bindEvents();

					// Fire optional callback
					callback && callback();
				}
			});
		} else {
			trigger.setAttribute('data-state', 'closed');
			content.setAttribute('data-state', 'closed');

			// Fire optional callback
			callback && callback();
		}
	};

	const toggle = trigger => {
		const idx = Number(trigger.getAttribute('data-index'));
		const content = contents[idx];
		const isOpen = trigger.getAttribute('data-state') === 'open';

		if (isOpen) {
			closeSection({ trigger, content });
		} else {
			openSection({ trigger, content });
		}
	};

	const onClick = e => {
		if (e.target.closest(instance.refSelector(config.triggerRef))) {
			toggle(e.target.closest(instance.refSelector(config.triggerRef)));
		}
	};

	const bindEvents = () => {
		instance.el.addEventListener('click', onClick);
	};
	const unbindEvents = () => {
		instance.el.removeEventListener('click', onClick);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;

		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);

		triggers = instance.ref(config.triggerRef, true);
		contents = instance.ref(config.contentRef, true);

		decorateSections();
		setInitialState();
		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
