import '../lib/browser/FlyingFocus';
import 'lazysizes/plugins/respimg/ls.respimg'; // polyfill for IE11
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/object-fit/ls.object-fit'; // polyfill for IE11
import 'lazysizes';
import '../lib/browser/detect-touch';
import { enable as enablePreventContextmenu } from 'app/modules/preventContextmenu';
import 'app/modules/MagneticHover';
import 'app/plugins/ScreenheightCSS';

enablePreventContextmenu(
	'img[data-sizes], .Image, .lazyload, .HomeExperience--slide img, .ProductOverlay--image img, .ProductDrawer--content img'
);

// Suuuper ugly!! But in a few cases we need to know if we're on IE11.
window.HV = window.HV || {};
window.HV.isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

// Detect firefox. Use only if really really necessary.
window.HV.isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
