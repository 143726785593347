export const getCookie = name => {
	const value = '; ' + document.cookie;
	const parts = value.split('; ' + name + '=');

	return parts.length !== 2
		? undefined
		: parts
				.pop()
				.split(';')
				.shift();
};

export const setCookie = (name, value, expiryDays, domain, path) => {
	const exdate = new Date();
	exdate.setDate(exdate.getDate() + (expiryDays || 365));

	const cookie = [name + '=' + value, 'expires=' + exdate.toUTCString(), 'path=' + (path || '/')];

	if (domain) {
		cookie.push('domain=' + domain);
	}

	document.cookie = cookie.join(';');
};
