/**
 * Cart
 *
 * @selector [data-js-update="Cart"]
 * @enabled true
 */
import contextTrigger from 'lib/util/contextTrigger';

export default function Cart() {
	// Private vars
	const instance = {};

	const getEl = name => instance.el.querySelector(`[data-el="${name}"]`);

	// Private methods

	const bindEvents = () => {
		const { el } = instance;
		el.addEventListener('submit', event => {
			event.preventDefault();
			el.classList.add('is-loading');

			// that's a very fancy way of `el.action` to work around an input named "action"
			let action = Object.getOwnPropertyDescriptor(HTMLFormElement.prototype, 'action').get.call(el);

			if (getEl('sku')) {
				action = location.protocol + '//' + location.host + location.pathname + '?variant=' + getEl('sku').value;
			}

			trackCartAction(event, el);

			loadAjaxPage(action, {
				method: el.method || 'GET',
				data: new FormData(el),
				history: 'push',
				target: [
					'.ContentDrawer--content--inner',
					'.Header--cart',
					'.ProductHeroInfo',
					'.ProductDrawer--configurator',
					'.PriceSummary'
				]
			})
				.then(() => {
					document.dispatchEvent(new Event('ContentDrawer:reInit'));
					document.dispatchEvent(new Event('closedrawer:ProductDrawer'));
					document.dispatchEvent(new Event('opendrawer:cartoverlay'));
					el.classList.remove('is-loading');
				})
				.catch(() => {
					el.classList.remove('is-loading');
				});
		});
	};

	const unbindEvents = () => {};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}

const defaultLoadPageOptions = {
	method: 'GET',
	target: ['body']
};

function loadAjaxPage(url, opts = {}) {
	return new Promise((resolve, reject) => {
		const options = { ...defaultLoadPageOptions, ...opts };
		const targetSelectors = options.target;

		const request = new XMLHttpRequest();
		request.open(options.method, url, true);

		request.onload = function() {
			if (request.status >= 200 && request.status < 400) {
				const response = request.responseText;
				let newEl;

				// save focus for restoring later
				const previousFocus = document.activeElement;
				const previousFocusSelector = deriveSelector(previousFocus);
				const previousFocusParent = targetSelectors.filter(selector => !!previousFocus.closest(selector))[0];

				// parse new document (create html nodes)
				const newDoc = document.implementation.createHTMLDocument('new');
				newDoc.documentElement.innerHTML = response;

				// replace target elements
				targetSelectors.forEach(selector => {
					replaceDocumentElement(newDoc, selector);
				});

				// update page title
				newEl = newDoc.querySelector('head > title');

				// update page url
				if (options.history === 'push') {
					window.history.pushState(null, newEl ? newEl.innerText : null, url);
				} else if (options.history === 'replace') {
					window.history.replaceState(null, newEl ? newEl.innerText : null, url);
				}

				if (newEl) {
					document.querySelector('head > title').innerText = newEl.innerText;
				}

				// restore focus (if possible --- which should always be the case)
				if (previousFocus !== document.activeElement) {
					newEl = document.querySelector(previousFocusSelector);
					if (newEl) {
						newEl.focus();
					} else if (previousFocusParent) {
						//Accessibility.focusFirst(previousFocusParent);
					}
				}

				resolve();
			} else {
				reject();
			}
		};
		console.log(options.data);
		request.send(options.data || {});
	});
}

function deriveSelector(el) {
	let n = el;
	const path = [];
	while (n) {
		if (n.getAttribute('id')) {
			path.unshift(`#${n.getAttribute('id')}`);
			break;
		}
		path.unshift(`${n.nodeName}:nth-child(${childIndex(n)})`);
		n = n.parentElement;
	}
	return path.join(' > ');
}

function childIndex(el) {
	const { children } = el.parentNode;
	for (let i = 0; i < children.length; i++) {
		if (children[i] === el) {
			return i + 1;
		}
	}
	return -1;
}

function replaceDocumentElement(srcDocument, selector) {
	return replaceElement(srcDocument, document, selector);
}

function replaceElement(srcDocument, dstDocument, selector) {
	const srcEls = Array.from(srcDocument.querySelectorAll(selector));
	const dstEls = Array.from(dstDocument.querySelectorAll(selector));
	const num = Math.min(srcEls.length, dstEls.length);
	if (num > 0) {
		for (let i = 0; i < num; i++) {
			const srcEl = srcEls[i];
			const dstEl = dstEls[i];
			dstEl.parentNode.replaceChild(srcEl, dstEl);
			contextTrigger.validate(srcEl);
		}

		return srcEls;
	}
	return false;
}

function trackCartAction(event, el) {
	const sourceElem = event.submitter;

	if (!sourceElem) {
		return;
	}

	let dataLayerPushItem = JSON.parse(sourceElem.getAttribute('data-tracking'));

	if (Object.keys(dataLayerPushItem).length > 0) {
		if (el.parentNode.getAttribute('data-js') === 'ProductConfigurator') {
			const parent = el.parentNode;
			let nodes = [];

			nodes.push({
				index: 'quantity',
				selector: el.querySelectorAll('[data-el="count"]')[0],
				int: true
			});

			try {
				nodes.push({
					index: 'price',
					selector: el
						.querySelectorAll('[data-el="price"]')[0]
						.textContent.split('CHF')[1]
						.replace('’', '')
						.replace('.—', '')
						.trim(),
					noValue: true
				});
			} catch (error) {
				nodes.push({
					index: 'price',
					selector: 0,
					noValue: true
				});
			}

			nodes.push({
				index: 'variant',
				selector: parent.querySelectorAll('[name="dimension-3"]:checked')[0]
			});

			nodes.push({
				index: 'dimension2',
				selector: parent.querySelectorAll('[name="dimension-0"]:checked')[0]
			});

			nodes.push({
				index: 'dimension3',
				selector: parent.querySelectorAll('[name="dimension-1"]:checked')[0]
			});

			nodes.push({
				index: 'dimension4',
				selector: parent.querySelectorAll('[name="dimension-2"]:checked')[0]
			});

			nodes.forEach(item => {
				if (typeof item.selector !== 'undefined') {
					try {
						dataLayerPushItem.ecommerce.add.products[0][item.index] = item.noValue
							? item.selector
							: item.int
							? parseInt(item.selector.value)
							: item.selector.value;
					} catch (error) {
						console.warn(error);
					}
				}
			});
		}

		if (sourceElem.getAttribute('data-ref') === 'Cart:item-remove') {
			const item = el.closest('[data-ref="Cart:item"]');
			let nodes = [];

			nodes.push({
				index: 'quantity',
				selector: item.querySelectorAll('[data-ref="AmountField:amountField"]')[0],
				int: true
			});

			try {
				nodes.push({
					index: 'price',
					selector: item
						.querySelectorAll('[data-ref="Cart:item-price"]')[0]
						.textContent.split('CHF')[1]
						.replace('’', '')
						.replace('.—', '')
						.trim(),
					noValue: true
				});
			} catch (error) {
				nodes.push({
					index: 'price',
					selector: 0,
					noValue: true
				});
			}

			nodes.forEach(item => {
				if (typeof item.selector !== 'undefined') {
					try {
						dataLayerPushItem.ecommerce.remove.products[0][item.index] = item.noValue
							? item.selector
							: item.int
							? parseInt(item.selector.value)
							: item.selector.value;
					} catch (error) {
						console.warn(error);
					}
				}
			});
		}

		window.dataLayer.push(dataLayerPushItem);
		console.log(window.dataLayer);
	}
}
