/**
 * Langselect
 *
 * @selector [data-js="Langselect"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { animateProps } from 'app/util/animate';
import { setStyles, clearStyles } from 'app/util/dom-helpers';
import { easeOutCubic } from 'app/util/easings';

// const defaults = {};

const config = {
	optionsAttr: 'data-options',
	overlayRef: 'overlay',
	currentRef: 'current',
	closeRef: 'close',
	listRef: 'list'
};

export default function Langselect() {
	// Private vars
	const instance = {};
	// let settings = {};
	let overlay;
	let current;
	let list;

	// Private methods
	const open = () => {
		setStyles([overlay, list], {
			opacity: 0,
			display: 'block'
		});

		animateProps({
			el: current,
			duration: 200,
			easing: easeOutCubic,
			props: [
				{
					propName: 'opacity',
					start: 1,
					end: 0
				}
			]
		});

		animateProps({
			el: [overlay, list],
			duration: 200,
			easing: easeOutCubic,
			props: [
				{
					propName: 'opacity',
					start: 0,
					end: 1
				}
			],
			onComplete: () => {
				clearStyles([overlay, list, current], ['opacity', 'display']);
				instance.setState('open');
				window.addEventListener('keydown', onKeydown);
			}
		});
	};

	const close = () => {
		setStyles(current, {
			opacity: 0,
			display: 'block'
		});

		animateProps({
			el: current,
			duration: 200,
			easing: easeOutCubic,
			props: [
				{
					propName: 'opacity',
					start: 0,
					end: 1
				}
			]
		});

		animateProps({
			el: [overlay, list],
			duration: 200,
			easing: easeOutCubic,
			props: [
				{
					propName: 'opacity',
					start: 1,
					end: 0
				}
			],
			onComplete: () => {
				clearStyles([overlay, list, current], ['opacity', 'display']);
				instance.setState('closed');
				window.removeEventListener('keydown', onKeydown);
			}
		});
	};

	const onClick = e => {
		if (e.target.closest(`${instance.refSelector(config.overlayRef)}, ${instance.refSelector(config.closeRef)}`)) {
			close();
			e.preventDefault();
			return;
		}

		if (e.target.closest(instance.refSelector(config.currentRef))) {
			open();
			e.preventDefault();
		}
	};

	const onKeydown = e => {
		let key = e.code || e.key;

		if (key === 'Escape') {
			close();
		}
	};

	const bindEvents = () => {
		instance.el.addEventListener('click', onClick);
	};

	const unbindEvents = () => {
		instance.el.removeEventListener('click', onClick);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		// let options = {};
		// if (el.hasAttribute(config.optionsAttr)) {
		// 	options = JSON.parse(el.getAttribute(config.optionsAttr));
		// }
		//
		// settings = Object.assign({}, defaults, options);
		overlay = instance.ref(config.overlayRef);
		current = instance.ref(config.currentRef);
		list = instance.ref(config.listRef);

		bindEvents();

		// Initial state
		instance.setState('closed');

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
