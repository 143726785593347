import fastdom from 'fastdom';

let current, currentLazy;
const isMobileDevice = typeof window.orientation !== 'undefined';

function validate() {
	let div, height;
	fastdom.mutate(prepare);

	function prepare() {
		div = document.createElement('div');
		div.style.position = 'fixed';
		div.style.top = '0px';
		div.style.height = '100%';
		div.style.left = '-1px';
		div.style.width = '1px';
		document.body.appendChild(div);

		fastdom.measure(measure);
	}
	function measure() {
		height = Math.min(div.offsetHeight, window.innerHeight);
		fastdom.mutate(apply);
	}

	function apply() {
		document.body.removeChild(div);
		const htmlEl = document.querySelector('html');

		if (height !== current) {
			current = height;
			htmlEl.style.setProperty('--screenheight', `${height}px`);
			// the lazy screenheight will only be changed when the height changes by at least 20% (see ScreenHeightCSS)
			// on mobile devices. This will prevent resize on scroll when part of UI shrinks to make place
			if (
				!currentLazy ||
				!isMobileDevice ||
				Math.abs((currentLazy - height) / currentLazy) >= 0.2
			) {
				currentLazy = height;
				htmlEl.style.setProperty('--screenheightLazy', `${height}px`);
			}
		}
	}
}
window.addEventListener('resize', validate);
validate();
export default validate;
