/**
 * InfoBanner
 *
 * @selector [data-js="InfoBanner"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { setCookie, getCookie } from 'app/util/cookie';
import { once } from 'app/util/once';

const config = {
	optionsAttr: 'data-options',
	cookieName: 'riposa-website-info-seen'
};

export default function InfoBanner() {
	// Private vars
	const instance = {};
	let closeBtn;

	// Private methods
	const onClose = () => {
		setCookie(config.cookieName, true);

		once(instance.el, 'transitionend', () => {
			instance.destroy();
			instance.el.remove();
		});

		instance.setState('');
	};

	const bindEvents = () => {
		closeBtn.addEventListener('click', onClose);
	};

	const unbindEvents = () => {
		closeBtn.removeEventListener('click', onClose);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		closeBtn = instance.ref('close');

		if (!getCookie(config.cookieName)) {
			// Cookie is not set. Show info banner
			instance.setState('visible');
		} else {
			// Cookie already accepted.
			instance.el.remove();
			return;
		}

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
