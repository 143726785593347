let preventSelector = '.no-contextmenu';

const onContextmenu = e => {
	if (e.target.closest(preventSelector)) {
		e.preventDefault();
	}
};

const onDragstart = e => {
	e.preventDefault();
};

const bindEvents = () => {
	document.body.addEventListener('contextmenu', onContextmenu);
	document.body.addEventListener('dragstart', onDragstart);
};

const unbindEvents = () => {
	document.body.removeEventListener('contextmenu', onContextmenu);
	document.body.removeEventListener('dragstart', onDragstart);
};

export const enable = selector => {
	if (selector) {
		preventSelector = selector;
	}

	bindEvents();
};

export const disable = () => {
	unbindEvents();
};
