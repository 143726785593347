/**
 * ContactForm
 *
 * @selector [data-js="ContactForm"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { animateProps } from 'app/util/animate';
import { setStyles, clearStyles } from 'app/util/dom-helpers';
import { easeOutCubic } from 'app/util/easings';

const defaults = {
	responseDelay: 1000 // ms
};

const config = {
	optionsAttr: 'data-options'
};

const serialize = form => {
	// Setup our serialized data
	let serialized = [];

	// Loop through each field in the form
	for (let i = 0; i < form.elements.length; i++) {
		let field = form.elements[i];

		// Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
		if (
			!field.name ||
			field.disabled ||
			field.type === 'file' ||
			field.type === 'reset' ||
			field.type === 'submit' ||
			field.type === 'button'
		)
			continue;

		// If a multi-select, get all selections
		if (field.type === 'select-multiple') {
			for (let n = 0; n < field.options.length; n++) {
				if (!field.options[n].selected) continue;
				serialized.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.options[n].value));
			}
		}

		// Convert field data to a query string
		else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
			serialized.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value));
		}
	}

	return serialized.join('&');
};

export default function ContactForm() {
	// Private vars
	const instance = {};
	let settings = {};
	let actionUrl;
	let method;
	let successMessage;
	let sendErrorMessage;

	const successPage = () => {
		const successUrl = successMessage.dataset.successUrl;
		if (successUrl) {
			window.location.href = successUrl;
			return true;
		}
		return false;
	};

	const emptyForm = () => {
		instance.el.reset();
	};
	const scrollUp = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	// Private methods
	const showSuccess = () => {
		// First remove any error styles on inputs
		let inputs = instance.el.querySelectorAll('[data-js="Input"]');
		for (let i = inputs.length; i--; ) {
			inputs[i].classList.remove('has-error');
		}

		// Show success message
		setStyles(successMessage, { display: 'block', opacity: '0' });
		animateProps({
			el: successMessage,
			duration: 500,
			easing: easeOutCubic,
			props: [
				{
					propName: 'opacity',
					start: 0,
					end: 1
				},
				{
					propName: 'transform',
					start: 15,
					end: 0,
					prefix: 'translateY(',
					suffix: '%)'
				}
			],
			onStart: () => {
				instance.setState('success');
			},
			onComplete: () => {
				clearStyles(successMessage, ['display', 'opacity', 'transform']);
			}
		});
	};

	const showServerError = () => {
		// Show success message
		setStyles(sendErrorMessage, { display: 'block', opacity: '0' });
		animateProps({
			el: sendErrorMessage,
			duration: 500,
			easing: easeOutCubic,
			props: [
				{
					propName: 'opacity',
					start: 0,
					end: 1
				},
				{
					propName: 'transform',
					start: 15,
					end: 0,
					prefix: 'translateY(',
					suffix: '%)'
				}
			],
			onComplete: () => {
				clearStyles(sendErrorMessage, ['display', 'opacity', 'transform']);
				instance.setState('send-error');
			}
		});
	};

	const showErrors = errors => {
		let inputComp;
		let scrolled = false;

		for (let fieldName in errors) {
			if (errors.hasOwnProperty(fieldName)) {
				inputComp = instance.el.querySelector(`input[name="${fieldName}"]`).closest('[data-js="Input"]');
				inputComp.classList.add('has-error');
				inputComp.querySelector('[data-ref="Input:error"]').innerHTML = errors[fieldName][0];

				if (!scrolled) {
					inputComp.scrollIntoView();
					scrolled = true;
				}
			}
		}

		instance.setState('');
	};

	const onSubmit = e => {
		e.preventDefault();

		if (instance.hasState('sending')) {
			return;
		}

		// Disable submit button
		instance.setState('sending');

		let xhr = new XMLHttpRequest();
		xhr.open(method, actionUrl);
		xhr.setRequestHeader('Accept', 'application/json, text/javascript, */*; q=0.01');
		xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
		xhr.onload = function() {
			setTimeout(() => {
				if (xhr.status === 200) {
					let response = JSON.parse(xhr.responseText);

					if (response.success) {
						if (!successPage()) {
							showSuccess();
							emptyForm();
							scrollUp();
						}
					} else {
						showErrors(response.errors);
					}
				} else {
					showServerError();
				}
			}, settings.responseDelay);
		};

		xhr.send(serialize(instance.el));
	};

	const bindEvents = () => {
		instance.el.addEventListener('submit', onSubmit);
	};

	const unbindEvents = () => {};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		actionUrl = instance.el.getAttribute('action');
		method = instance.el.getAttribute('method');
		successMessage = instance.ref('success');
		sendErrorMessage = instance.ref('sendError');

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
