// Inspired by https://github.com/hammerjs/hammer.js/blob/master/src/touchactionjs/get-touchaction-props.js

const supports = typeof window !== 'undefined' && (window.CSS && window.CSS.supports);
const hasTouchAction = typeof document !== 'undefined' && document.documentElement.style['touchAction'] !== undefined;
const touchProps = {};

['auto', 'none', 'pan-x', 'pan-y', 'pan-left', 'pan-right', 'pan-up', 'pan-down', 'pinch-zoom', 'manipulation'].forEach(
	prop => {
		touchProps[prop] = supports ? window.CSS.supports('touch-action', prop) : hasTouchAction;
	}
);

export { touchProps };
