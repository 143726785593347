/**
 * Smoothscroll
 *
 * @selector [data-js="Smoothscroll"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { animateValue } from 'app/util/animate';
import { easeOutCubic } from 'app/util/easings';

const defaults = {
	animDuration: 400
};

const config = {
	optionsAttr: 'data-options'
};

export default function Smoothscroll() {
	// Private vars
	const instance = {};
	let settings = {};
	let target;
	let targetPos;

	// Private methods
	const onClick = e => {
		e.preventDefault();
		e.stopPropagation();

		let scrollY = window.scrollY || window.pageYOffset;
		targetPos = target.getBoundingClientRect().top + scrollY;

		animateValue({
			duration: settings.animDuration,
			start: scrollY,
			end: targetPos,
			easing: easeOutCubic,
			onUpdate: data => {
				window.scrollTo(0, data);
			},
			onComplete: () => {
				window.history.pushState(null, null, instance.el.hash);
			}
		});
	};

	const bindEvents = () => {
		instance.el.addEventListener('click', onClick);
	};

	const unbindEvents = () => {
		instance.el.removeEventListener('click', onClick);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		target = document.getElementById(instance.el.hash.replace('#', ''));

		if (target) {
			bindEvents();
		}

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
