/**
 * DealerInfoList
 *
 * @selector [data-js="DealerInfoList"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { pubsub } from 'app/modules/pubsub';
import { once } from 'app/util/once';
import { width as vpWidth } from 'app/util/viewport';
import { animateValue } from 'app/util/animate';
import { easeInOutCubic } from 'app/util/easings';

const defaults = {
	mobileBreakpoint: 750
};

const config = {
	optionsAttr: 'data-options',
	rowRef: 'row',
	regionRef: 'region',
	noResultRef: 'noResult',
	mainListRef: 'mainList',
	sortedList: 'sortedList'
};

export default function DealerInfoList() {
	// Private vars
	const instance = {};
	let settings = {};
	let noResult;
	let allRows;
	let sortedList;

	// Private methods
	const updateRegionVisibility = () => {
		let regions = instance.el.querySelectorAll(instance.refSelector(config.regionRef));
		let rowSelector = `${instance.refSelector(config.rowRef)}[data-state="hidden"]`;

		for (let i = regions.length; i--; ) {
			if (Number(regions[i].getAttribute('data-count')) === regions[i].querySelectorAll(rowSelector).length) {
				regions[i].setAttribute('data-state', 'hidden');
			} else {
				regions[i].setAttribute('data-state', '');
			}
		}
	};

	const doSearch = data => {
		noResult.setAttribute('data-state', data.dealers.length === 0 ? 'show' : '');

		once(instance.el, 'animationend', () => {
			instance.el.classList.remove('flash');
		});

		instance.el.classList.add('flash');

		if (!data.sortByDistance) {
			instance.setState('');
			sortedList.innerHTML = 0;

			let dealerIds = data.dealers.map(d => d.id);
			for (let i = allRows.length; i--; ) {
				if (dealerIds.indexOf(allRows[i].getAttribute('data-dealer-id')) > -1) {
					allRows[i].setAttribute('data-state', '');
				} else {
					allRows[i].setAttribute('data-state', 'hidden');
				}
			}

			updateRegionVisibility();
		} else {
			instance.setState('sorted');

			// First sort our result dealer ids
			let dealerIds = data.dealers
				.sort((a, b) => {
					if (a.distance < b.distance) {
						return -1;
					}
					if (a.distance > b.distance) {
						return 1;
					}
					// a muss gleich b sein
					return 0;
				})
				.map(d => d.id);

			// We have a sorted array of dealer ids. cool.
			let tmpDiv = document.createElement('div');
			let tmpNode;
			for (let i = 0; i < dealerIds.length; i++) {
				tmpNode = allRows.find(r => dealerIds[i] === r.getAttribute('data-dealer-id')).cloneNode(true);
				tmpNode.setAttribute('data-state', '');
				tmpDiv.appendChild(tmpNode);
			}

			sortedList.innerHTML = tmpDiv.innerHTML;
			tmpDiv = null;
		}

		// On mobile scroll to results
		if (vpWidth() <= settings.mobileBreakpoint) {
			let scrollY = window.scrollY || window.pageYOffset;
			let targetPos = instance.el.getBoundingClientRect().top + scrollY;

			animateValue({
				duration: 500,
				start: scrollY,
				end: targetPos,
				easing: easeInOutCubic,
				onUpdate: data => {
					window.scrollTo(0, data);
				}
			});
		}
	};

	const bindEvents = () => {
		pubsub.on('DealerMap.search', doSearch);
	};

	const unbindEvents = () => {
		pubsub.off('DealerMap.search', doSearch);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		noResult = instance.ref(config.noResultRef);
		sortedList = instance.ref(config.sortedList);
		allRows = Array.from(instance.el.querySelectorAll(instance.refSelector(config.rowRef)));

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
