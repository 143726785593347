/**
 * Checks if given coordinates are within given rectangle.
 *
 * @param x
 * @param y
 * @param bounds - Must contain 'top', 'left', 'width', 'height'
 * @param padding - optional padding
 */
export const inRect = ({ x, y, bounds, padding = 0 }) => {
	return (
		x >= bounds.left + padding &&
		x <= bounds.left + bounds.width - padding &&
		y >= bounds.top + padding &&
		y <= bounds.top + bounds.height - padding
	);
};
