/**
 * Navigation
 *
 * @selector [data-js="ProductHeroInfo"]
 * @enabled true
 */
import { base } from 'app/util/base';

export default function HeroInfo() {
	// Private vars
	const instance = {};

	const bindEvents = () => {};

	const unbindEvents = () => {};

	// Public vars
	instance.ns = 'HeroInfo';

	// Public methods
	instance.init = element => {
		instance.el = element;

		Object.assign(instance, base(instance));
		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
