/**
 * HomeExperience
 *
 * @selector [data-js="HomeExperience"]
 * @enabled true
 */
import { base } from 'app/util/base';

const config = {
	optionsAttr: 'data-options'
};

/* HomeExperience module.
 */
export default function HomeExperience() {
	// Private vars
	const instance = {};

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));
		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		const slideIndex = Math.floor(Math.random() * options.length),
			slide = options[slideIndex],
			heroFigure = element.querySelector('figure');

		if (heroFigure) {
			const heroCaption = element.querySelector('figcaption');
			if (slide.position) {
				heroFigure.classList.add(slide.position);
			}
			heroCaption.innerHTML = slide.text;

			const heroImg = heroFigure.querySelector('img');
			heroImg.onload = event => {
				if (event) {
					heroFigure.classList.remove('loading');
				}
			};
			const srcset = slide.img.small + ' 1000w, ' + slide.img.big + ' 2000w';
			heroImg.setAttribute('srcset', srcset);
			heroImg.src = slide.img.small;
			if (heroImg.complete) {
				heroFigure.classList.remove('loading');
			}
		}

		return instance;
	};

	return instance;
}
