/**
 * CheckoutSection
 *
 * @selector [data-js="CheckoutSection"]
 * @enabled true
 */
import { base } from 'app/util/base';

export default function CheckoutSection() {
	// Private vars
	const instance = {};
	let classTogglerNode;

	let requiredElems;

	// Private methods
	//
	const onSameBillingAddress = e => {
		const node = (e && e.target) || classTogglerNode;
		const activate = node.checked;
		instance.el.classList[activate ? 'add' : 'remove'](node.name);

		requiredElems.forEach(elem => {
			if (activate) {
				if (!elem.offsetWidth > 0 && !elem.offsetHeight > 0) {
					elem.removeAttribute('required');
				}
			} else {
				if (elem.offsetWidth > 0 && elem.offsetHeight > 0) {
					elem.setAttribute('required', '');
				}
			}
		});
	};

	const bindEvents = () => {
		classTogglerNode && classTogglerNode.addEventListener('input', onSameBillingAddress);
	};

	const unbindEvents = () => {
		classTogglerNode && classTogglerNode.removeEventListener('input', onSameBillingAddress);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		classTogglerNode = instance.ref('checkbox-name-class-toggle');
		requiredElems = instance.el.querySelectorAll('[required]');

		bindEvents();
		onSameBillingAddress();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
