let vpWidth;
let vpHeight;
let _innerWidth;
let _innerHeight;
let _clientWidth;
let _clientHeight;

const onResize = () => {
	_innerWidth = window.innerWidth;
	_innerHeight = window.innerHeight;
	_clientWidth = document.documentElement.clientWidth;
	_clientHeight = document.documentElement.clientHeight;
	vpWidth = _innerWidth || _clientWidth;
	vpHeight = _innerHeight || _clientHeight;
};

window.addEventListener('resize', onResize, { passive: true });

// Set initial values
onResize();

export const width = () => vpWidth;
export const height = () => vpHeight;
export const isPortrait = () => vpWidth < vpHeight;
export const isLandscape = () => vpWidth > vpHeight;
