export const once = function(el, eventName, callback, useCapture = false) {
	el.addEventListener(
		eventName,
		function listener(e) {
			callback.call(null, e);

			el.removeEventListener(eventName, listener, useCapture);
		},
		useCapture
	);
};
