/**
 * ProductConfigurator
 *
 * @selector [data-js="ProductConfigurator"]
 * @enabled true
 */
import { base } from 'app/util/base';

export default function ProductConfigurator() {
	// Private vars
	const instance = {};
	const configuration = { dimensions: [], variants: [] };
	const configurationVisuals = {};

	let counter, incrementButton, decrementButton;

	const increment = event => {
		event.preventDefault();
		const count = counter.value || 1;
		if (count < 1000) {
			counter.value = +count + 1;
		}
		validateVariant();
	};

	const decrement = event => {
		event.preventDefault();
		const count = counter.value || 1;
		if (count > 1) {
			counter.value = +count - 1;
		}
		validateVariant();
	};
	const intl = new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' });
	const price = {
		el: {},
		previous: 0,
		current: 0,
		update: () => {
			price.previous += (price.current - price.previous) / 20;
			price.el.textContent = intl.format(Math.round(price.previous)).replace('.00', '.—');
			if (price.current != price.previous) {
				requestAnimationFrame(price.update);
			}
		},
		set: value => {
			if (value >= 0 && price.el) {
				price.current = value;
				price.update();
			}
		}
	};

	const change = () => {
		validateVariant();
	};

	const validateVariant = () => {
		const confForm = getEl('inputForm');
		const formdata = new FormData(confForm);
		const values = Array.from(formdata.values());
		const valuesString = values.toString();
		const variant = configuration.variants.find(v => v.options.toString() === valuesString);

		if (
			variant &&
			variant.options &&
			configurationVisuals &&
			Object.keys(configurationVisuals) &&
			Object.keys(configurationVisuals).length > 0
		) {
			const productVisual = document.querySelector('[data-el="ProductConfigurator-visual"]');
			const configurationVisualKeys = Object.keys(configurationVisuals);

			const variantImage =
				configurationVisuals[
					configurationVisualKeys[configurationVisualKeys.findIndex(key => variant.options.indexOf(key) > -1)]
				];

			productVisual.setAttribute('src', variantImage);
		}

		if (variant) {
			const count = getEl('count').value || 1;
			price.set(count * variant.price);
			// update sku
			getEl('sku').value = variant.sku;
		} else {
			console.log('no variant found');
		}
	};

	const bindEvents = () => {
		incrementButton.addEventListener('click', increment);
		decrementButton.addEventListener('click', decrement);
		getEl('inputForm').addEventListener('change', change);
		counter.addEventListener('input', change);
	};

	const unbindEvents = () => {
		incrementButton.removeEventListener('click', increment);
		decrementButton.removeEventListener('click', decrement);
		getEl('inputForm').removeEventListener('change', change);
		counter.removeEventListener('input', change);
	};

	const getEl = name => instance.el.querySelector(`[data-el="${name}"]`);

	// Public vars
	instance.ns = 'ProductConfigurator';

	// Public methods
	instance.init = element => {
		instance.el = element;
		price.el = getEl('price');
		try {
			Object.assign(configuration, JSON.parse(getEl('configuration').innerText));
		} catch (e) {
			console.log('Could not parse configuration', e);
		}
		try {
			Object.assign(configurationVisuals, JSON.parse(getEl('configuration-visuals').innerText));
		} catch (e) {
			console.log('Could not parse configuration-visuals', e);
		}

		counter = getEl('count');
		incrementButton = getEl('increment');
		decrementButton = getEl('decrement');

		Object.assign(instance, base(instance));
		bindEvents();
		validateVariant();
		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
