let scrollX = 0;
let scrollY = 0;

const onScroll = () => {
	scrollX = window.scrollX || window.pageXOffset || document.documentElement.scrollLeft;
	scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
};

window.addEventListener('scroll', onScroll, { passive: true });

// Set initial values
onScroll();

export const getScrollX = () => scrollX;
export const getScrollY = () => scrollY;
