/**
 * VideoTeaser
 *
 * @selector [data-js="VideoTeaser"]
 * @enabled true
 */
import { base } from 'app/util/base';

export default function VideoTeaser() {
	// Private vars
	const instance = {};

	const mobile = window.matchMedia('(max-width: 800px)');

	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if (entry.intersectionRatio > 0) {
				instance.el.play();
				instance.el.classList.add('playing');
				observer.unobserve(entry.target);
			}
		});
	});

	// Private methods

	const video = e => {
		const d = instance.el.dataset;
		instance.el.src = d[e.matches ? (d['mobile'] ? 'mobile' : 'desktop') : 'desktop'];
		instance.el.poster = d[e.matches ? (d['postermobile'] ? 'postermobile' : 'posterdesktop') : 'posterdesktop'];
		instance.el.play();
	};

	const bindEvents = () => {
		observer.observe(instance.el);
		mobile.addEventListener('change', video);
	};

	const unbindEvents = () => {
		observer.unobserve(instance.el);
		mobile.removeEventListener('change', video);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		instance.el.muted = true;
		Object.assign(instance, base(instance));
		video(mobile);
		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
