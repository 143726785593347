/**
 * Slider
 *
 * @selector [data-js="Slider"]
 * @enabled true
 */

// TODO: Add align option. contain should also work when dragEnabled is false.

import { base } from 'app/util/base';
import { touchProps } from 'app/util/touch-props';
import { pointerdownEvent, pointermoveEvent, pointerupEvent } from 'app/util/pointer-event-names';
import { resizeeventsManager, scrolleventsManager } from 'app/util/events-manager';
import { inRect } from 'app/util/in-rect';
import { once } from 'app/util/once';
import * as transitions from './SliderTransitions';
import { clearStyles, setStyles } from 'app/util/dom-helpers';
import { round } from 'app/util/round';
import { width as vpWidth } from 'app/util/viewport';
import { pubsub } from 'app/modules/pubsub';

const defaults = {
	disableOnBreakpoint: false, // Disable slider below given browser viewport width.
	transition: 'slide',
	infiniteLoop: false, // There should be at least 3 slides for infiniteLoop to work. Slides must have 100% width.
	dragEnabled: false,
	align: 'center', // Alignment of active cell. 'left', 'center' or 'right'
	contain: false, // Has no effect with full width slides or when infiniteLoop is enabled.
	minFlickVelocity: 0.5, // Lower value is more sensitive, will trigger flick action more easily
	tolerance: 20 // Amount of px before actual sliding occurs
};

const config = {
	optionsAttr: 'data-options',
	viewportRef: 'viewport',
	sliderRef: 'slider',
	slideRef: 'slide',
	paginationDotRef: 'dot',
	nextRef: 'next',
	previousRef: 'previous',
	readyState: 'is-ready',
	hidePaginationState: 'hide-pagination',
	isDisabledState: 'is-disabled'
};

let uid = -1;

const isMouse = pointerdownEvent === 'mousedown';

const setTouchProperties = el => {
	if (isMouse) {
		return;
	}

	const style = [];

	if (touchProps['pan-y']) {
		style.push('pan-y');

		if (touchProps['pinch-zoom']) {
			style.push('pinch-zoom');
		}
	} else {
		style.push('none');
	}

	el.style.touchAction = style.join(' ');
};

const getDimensions = el => {
	const rect = el.getBoundingClientRect();

	return {
		top: rect.top,
		width: rect.width,
		height: rect.height,
		left: rect.left
	};
};

const getFlickVelocity = ({ startPos, endPos, startTime, endTime }) => ({
	velocity: Math.abs(endPos - startPos) / (endTime - startTime),
	direction: endPos < startPos ? 'left' : 'right'
});

/**
 * Get x position of desired slide.
 * Returns number without unit.
 *
 * @param slideCollection
 * @param index
 * @param unit - Choose which value of width. px or %
 * @returns {number}
 */
const getXofSlider = ({ slideCollection, index, unit }) => {
	unit = unit || 'px';

	let x = 0;

	for (let i = 0; i < index; i++) {
		if (unit === 'px') {
			x += slideCollection[i].widthPx;
		}

		if (unit === '%') {
			x += slideCollection[i].widthPercent;
		}
	}

	return x;
};

export default function Slider() {
	// Private vars
	const instance = {};
	let settings = {};
	let viewport;
	let slider;
	let paginationDots;
	let slideCollection = [];
	let slidesCount = 0;
	let containerBounds = {};
	let pointerX = 0;
	let pointerY = 0;
	let pointerdownTime = 0;
	let pointerupTime = 0;
	let startX = 0;
	let doRender = false;
	let renderId = -1;
	let x = 0; // x position of slider in px.
	let pointerEndX = 0;
	let pointerEndY = 0;
	let activeSlide = 0;
	let distance = 0; // Used for infinite loop feature
	// let sliderEnabled = false;
	let gotoFromControl = false;
	let dragEasing = 'ease-out';

	// Private methods
	const getSlides = () => {
		const collection = [];
		let slides = instance.ref(config.slideRef, true);

		for (let i = 0; i < slides.length; i++) {
			slides[i].setAttribute('data-index', i.toString());

			collection.push({
				el: slides[i]
			});
		}

		return collection;
	};

	const setSlideWidths = collection => {
		let widthPercent;
		let widthPx;

		for (let i = 0; i < collection.length; i++) {
			if (settings.infiniteLoop) {
				widthPercent = 100;
				widthPx = containerBounds.width;
			} else {
				let r = collection[i].el.getBoundingClientRect();
				widthPercent = (r.width / containerBounds.width) * 100;
				widthPx = r.width;
			}

			collection[i].widthPercent = round({
				value: widthPercent,
				decimals: 2
			});

			collection[i].widthPx = round({
				value: widthPx,
				decimals: 0
			});
		}
	};

	const renderSliderWrapper = () => {
		if (doRender) {
			slider.style.transform = `translateX(${round({
				value: (x / containerBounds.width) * 100, // Get % value from x (pixel value)
				decimals: 4
			})}%)`;
		}

		doRender = false;

		renderId = requestAnimationFrame(renderSliderWrapper);
	};

	const stopRenderSliderWrapper = () => {
		cancelAnimationFrame(renderId);
		clearStyles(slider, ['willChange', 'transition']);
	};

	const transitionTo = ({ currentIndex, newIndex }) => {
		if ((newIndex < 0 || newIndex > slidesCount - 1) && !settings.infiniteLoop) {
			return;
		}

		unbindEvents();

		instance.setState('sliding');

		transitions[settings.transition]({
			slides: slideCollection,
			sliderWrapper: slider,
			currentIndex,
			newIndex,
			bounds: containerBounds,
			isInfiniteLoop: settings.infiniteLoop,
			onComplete: propsToClear => {
				if (settings.infiniteLoop) {
					if (newIndex > slidesCount - 1) {
						newIndex = 0;
					}

					if (newIndex < 0) {
						newIndex = slidesCount - 1;
					}

					distance = newIndex - currentIndex;
					activeSlide = newIndex;
					updateSlidesWrapperFill();
				} else {
					// Set slidewrapper positon
					let tmpX = 0;
					for (let i = 0; i < newIndex; i++) {
						tmpX += slideCollection[i].widthPx;
					}

					x = tmpX * -1;

					clearStyles([slideCollection[newIndex].el, slideCollection[activeSlide].el], propsToClear);

					doRender = true;
					renderSliderWrapper();
					stopRenderSliderWrapper();
				}

				bindEvents();

				activeSlide = newIndex;
				updatePagination(activeSlide);

				instance.setState('');
			}
		});
	};

	const getVisibleIndex = () => {
		let tmpX;
		let idx;

		if (settings.dragEnabled) {
			if (settings.infiniteLoop) {
				let slidesTmp = Array.from(slider.querySelectorAll(instance.refSelector(config.slideRef)));
				let slidesIndex;
				tmpX = slidesTmp.reduce((acc, val, idx) => {
					if (idx < slidesCount) {
						acc += slideCollection[Number(val.getAttribute('data-index'))].widthPx;
					}

					return acc;
				}, 0);

				for (slidesIndex = 0; slidesIndex < slidesTmp.length; slidesIndex++) {
					if (x <= tmpX && x >= tmpX - containerBounds.width) {
						//currently visible index
						idx = Number(slidesTmp[slidesIndex].getAttribute('data-index'));
						break;
					}

					tmpX -= Number(slideCollection[Number(slidesTmp[slidesIndex].getAttribute('data-index'))].widthPx);
				}

				let xDistance =
					tmpX -
					Number(slideCollection[Number(slidesTmp[slidesIndex + 1].getAttribute('data-index'))].widthPx) -
					tmpX;

				if (x < 0 && x / xDistance > 0.5) {
					idx++;
				} else if (x > 0) {
					if (x / xDistance > -0.5) {
						idx++;
					} else {
						idx = activeSlide + (slidesIndex - slidesCount);
					}
				}

				return idx;
			} else {
				let absX = Math.abs(x);
				idx = 0;
				tmpX = 0;
				for (let i = 0; i < slideCollection.length; i++) {
					if ((absX - tmpX) / slideCollection[i].widthPx < 0.5) {
						idx = i;
						break;
					}

					tmpX += slideCollection[i].widthPx;
				}

				return idx;
			}
		} else {
			return activeSlide;
		}
	};

	const updateSlidePos = index => {
		if (index === 0) {
			instance.el.setAttribute('data-slide-pos', 'first');
		} else if (index === slidesCount - 1) {
			instance.el.setAttribute('data-slide-pos', 'last');
		} else {
			instance.el.removeAttribute('data-slide-pos');
		}
	};

	const goTo = ({ index, animated = true }) => {
		if (gotoFromControl) {
			dragEasing = 'ease-in-out';
		} else {
			dragEasing = 'ease-out';
		}

		updateSlidePos(index);

		// Reset this to default
		gotoFromControl = false;

		const onTransitionend = () => {
			bindEvents();

			stopRenderSliderWrapper();

			if (settings.infiniteLoop) {
				updateSlidesWrapperFill();
			}
		};

		if (
			(x === getXofSlider({ slideCollection, index: slidesCount - 1 }) * -1 || x === 0) &&
			index === activeSlide
		) {
			animated = false;
		}

		if (!settings.dragEnabled) {
			if (index === activeSlide) {
				return;
			}

			// Use one of the defined transitions instead of simply moving the given slide.
			transitionTo({
				currentIndex: activeSlide,
				newIndex: index
			});

			return;
		}

		// For when dragEnabled is on
		if (settings.infiniteLoop) {
			let next = false;

			// 1. Get direction
			if (index > activeSlide) {
				next = true;
			}

			distance = index - activeSlide;

			// 1a. We got the direction. correct index value
			if (index > slidesCount - 1) {
				index = 0;
			} else if (index < 0) {
				index = slidesCount - 1;
			}

			// 2. Set target x position
			let newX = 0;
			if (index === activeSlide) {
				newX = 0;
			} else {
				newX = next
					? slideCollection[index].widthPx * Math.abs(distance) * -1
					: slideCollection[index].widthPx * Math.abs(distance);
			}

			// 3. Actually move the slidewrapper
			if (newX === x) {
				activeSlide = index;
				onTransitionend();
			} else {
				slider.style.transition = 'transform .36s ' + dragEasing;

				// Remove transition when animation is done.
				once(slider, 'transitionend', onTransitionend);

				x = newX;
				unbindEvents();
				doRender = true;
				renderSliderWrapper();

				// 4. Set correct activeSlide index
				activeSlide = index;
			}

			updatePagination(activeSlide);

			if (!animated) {
				updateSlidesWrapperFill();
			}
		} else {
			if (index < 0 || index > slidesCount - 1) {
				return;
			}

			if (index > activeSlide && settings.contain) {
				let currentX = getXofSlider({ slideCollection, index: activeSlide }) * -1;

				if (currentX <= getXofSlider({ slideCollection, index: slidesCount }) * -1 + containerBounds.width) {
					return;
				}
			}

			// Set slidewrapper positon
			if (getXofSlider({ slideCollection, index, unit: 'px' }) !== x) {
				slider.style.transition = 'transform 0.2s ease-out';

				// Remove transition when animation is done.
				once(slider, 'transitionend', onTransitionend);

				// Actually move the slidewrapper
				x = -getXofSlider({ slideCollection, index, unit: 'px' });

				unbindEvents();
				doRender = true;
				renderSliderWrapper();
			}

			activeSlide = index;
			updatePagination(activeSlide);
		}

		if (!animated) {
			bindEvents();
		}
	};

	const updatePagination = index => {
		for (let i = paginationDots.length; i--; ) {
			if (i === index) {
				paginationDots[i].classList.add('is-active');
			} else {
				paginationDots[i].classList.remove('is-active');
			}
		}
	};

	const allSlidesVisible = () => {
		if (slidesCount === 1) {
			return true;
		}

		return (
			slidesCount > 1 &&
			slideCollection.reduce((acc, val) => {
				acc += val.widthPercent || 0;
				return acc;
			}, 0) <= 100
		);
	};

	const disableSlider = () => {
		unbindEvents();
		instance.el.classList.add(config.hidePaginationState);
		instance.el.classList.add(config.isDisabledState);
		instance.el.removeEventListener('click', preventDefault);

		if (settings.dragEnabled) {
			instance.el.classList.remove('drag-enabled');
			settings.dragEnabled = false;
			settings.__dragEnabled = true;
		}
		setStyles(slider, { transform: 'translateX(0)' });
	};

	const toggleBehaviour = () => {
		if (allSlidesVisible()) {
			disableSlider();
		} else {
			bindEvents();
			updatePagination(activeSlide);
			instance.el.classList.remove(config.hidePaginationState);
			instance.el.classList.remove(config.isDisabledState);

			if (settings.dragEnabled || settings.__dragEnabled) {
				instance.el.classList.add('drag-enabled');
				settings.dragEnabled = true;
				settings.__dragEnable = false;
			}
		}
	};

	const updateSlidesWrapperFill = (forceFill = false) => {
		// 1. We need (slideCount - 1) before and after viewport.
		let indexesBefore = [];
		let indexesAfter = [];
		let tmp = activeSlide;

		for (let i = 0; i < slidesCount; i++) {
			indexesBefore.push(tmp === 0 ? slidesCount - 1 : tmp - 1);
			tmp = indexesBefore[i];
		}

		indexesBefore = indexesBefore.reverse();

		tmp = activeSlide;
		for (let i = 0; i < slidesCount; i++) {
			indexesAfter.push(tmp === slidesCount - 1 ? 0 : tmp + 1);
			tmp = indexesAfter[i];
		}

		// 2. Fill up slidesWrapper with one item before and after current activeSlide index.
		if (forceFill) {
			slider.innerHTML = '';

			for (let i = 0; i < slidesCount; i++) {
				slider.appendChild(slideCollection[indexesBefore[i]].el.cloneNode(true));
			}

			slider.appendChild(slideCollection[activeSlide].el.cloneNode(true));

			for (let i = 0; i < slidesCount; i++) {
				slider.appendChild(slideCollection[indexesAfter[i]].el.cloneNode(true));
			}
		} else {
			// Remove only 'overflowing' slides
			let slidesToRemove = [];
			if (distance > 0) {
				slidesToRemove = slider.querySelectorAll(
					`${instance.refSelector(config.slideRef)}:nth-child(-n+${distance})`
				);
			}

			if (distance < 0) {
				slidesToRemove = slider.querySelectorAll(
					`${instance.refSelector(config.slideRef)}:nth-last-child(-n+${Math.abs(distance)})`
				);
			}

			for (let i = slidesToRemove.length; i--; ) {
				slider.removeChild(slidesToRemove[i]);
			}

			// Add new slides
			if (distance > 0) {
				indexesAfter = indexesAfter
					.reverse()
					.slice(0, distance)
					.reverse();

				for (let i = 0; i < distance; i++) {
					slider.appendChild(slideCollection[indexesAfter[i]].el.cloneNode(true));
				}
			}

			if (distance < 0) {
				indexesBefore = indexesBefore.slice(0, Math.abs(distance)).reverse();

				for (let i = 0; i < Math.abs(distance); i++) {
					slider.prepend(slideCollection[indexesBefore[i]].el.cloneNode(true));
				}
			}
		}

		// re-center slider
		setStyles(slider, { transform: 'translateX(0)' });
		x = 0;
	};

	const onPointerdown = e => {
		const downX = e.clientX || e.targetTouches[0].clientX;
		const downY = e.clientY || e.targetTouches[0].clientY;

		// Check if we clicked within slider bounds
		if (!inRect({ x: downX, y: downY, bounds: containerBounds })) {
			return;
		}

		pointerX = downX;
		pointerY = downY;
		pointerdownTime = window.performance.now();
		startX = x;

		setStyles(slider, {
			willChange: 'transform'
		});

		if (settings.dragEnabled) {
			renderSliderWrapper();
		}

		document.addEventListener(pointermoveEvent, onPointermove);
		document.addEventListener(pointerupEvent, onPointerup);

		if (isMouse || e.pointerType === 'mouse') {
			e.preventDefault();
		}
	};

	const onPointermove = e => {
		instance.setState('sliding');

		let diffX = (e.clientX || e.changedTouches[0].clientX) - pointerX;

		if (Math.abs(diffX) < settings.tolerance) {
			return;
		}

		if (settings.dragEnabled) {
			// Factor in the tolerance value
			if (diffX < 0) {
				diffX += settings.tolerance;
			} else {
				diffX -= settings.tolerance;
			}

			if (settings.infiniteLoop) {
				// Allow sliding only one viewport width

				if (diffX > containerBounds.width) {
					diffX = containerBounds.width;
				} else if (diffX < containerBounds.width * -1) {
					diffX = containerBounds.width * -1;
				}

				// Allow going back and forth.
				x = startX + diffX;
			} else {
				if (startX + diffX > 0) {
					// Don't overflow to left
					x = 0;
				} else if (
					settings.contain &&
					startX + diffX < getXofSlider({ slideCollection, index: slidesCount }) * -1 + containerBounds.width
				) {
					// Don't overflow to right
					x = getXofSlider({ slideCollection, index: slidesCount }) * -1 + containerBounds.width;
				} else if (startX + diffX < getXofSlider({ slideCollection, index: slidesCount - 1 }) * -1) {
					// Don't overflow to right
					x = getXofSlider({ slideCollection, index: slidesCount - 1 }) * -1;
				} else {
					x = startX + diffX;
				}
			}
		}

		doRender = true;

		e.preventDefault();
	};

	const onPointerup = e => {
		instance.setState('');

		const upX = e.clientX || e.changedTouches[0].clientX;
		const upY = e.clientY || e.changedTouches[0].clientY;

		stopRenderSliderWrapper();

		pointerupTime = window.performance.now();
		pointerEndX = upX;
		pointerEndY = upY;

		clearStyles(slider, ['willChange']);

		const flickInfo = getFlickVelocity({
			startPos: pointerX,
			endPos: pointerEndX,
			startTime: pointerdownTime,
			endTime: pointerupTime
		});

		const visibleIndex = getVisibleIndex();

		if (
			Math.abs(pointerEndX - pointerX) <= settings.tolerance &&
			Math.abs(pointerEndY - pointerY) <= settings.tolerance
		) {
			// 	// It's a click.
			// 	// Just do nothing i guess...
		} else if (flickInfo.velocity >= settings.minFlickVelocity) {
			goTo({ index: flickInfo.direction === 'left' ? activeSlide + 1 : activeSlide - 1 });
		} else if (visibleIndex !== activeSlide) {
			goTo({ index: visibleIndex });
		} else {
			goTo({ index: visibleIndex, animated: !(Math.abs(pointerEndX - pointerX) <= settings.tolerance) });
		}

		document.removeEventListener(pointermoveEvent, onPointermove);
		document.removeEventListener(pointerupEvent, onPointerup);
	};

	const onResize = () => {
		if (settings.disableOnBreakpoint && vpWidth() < settings.disableOnBreakpoint) {
			disableSlider();

			// sliderEnabled = false;
		} else {
			containerBounds = getDimensions(viewport);
			setSlideWidths(slideCollection);

			toggleBehaviour();

			if (!settings.infiniteLoop) {
				x = -getXofSlider({ slideCollection, index: activeSlide });
			}
		}
	};

	const onScroll = () => {
		containerBounds = getDimensions(viewport);
	};

	const onClick = e => {
		if (e.target.closest(instance.refSelector(config.paginationDotRef))) {
			e.preventDefault();

			const dot = e.target.closest(instance.refSelector(config.paginationDotRef));

			gotoFromControl = true;

			goTo({
				index: Number(dot.getAttribute('data-index'))
			});
		}

		if (e.target.closest(instance.refSelector(config.nextRef))) {
			e.preventDefault();

			gotoFromControl = true;

			goTo({ index: activeSlide + 1 });
		}

		if (e.target.closest(instance.refSelector(config.previousRef))) {
			e.preventDefault();

			gotoFromControl = true;

			goTo({ index: activeSlide - 1 });
		}
	};

	const preventDefault = e => {
		e.preventDefault();
	};

	const bindEvents = () => {
		instance.el.addEventListener('click', onClick);
		document.addEventListener(pointerdownEvent, onPointerdown);
		scrolleventsManager.add(onScroll);

		instance.el.removeEventListener('click', preventDefault);
	};

	const unbindEvents = () => {
		instance.el.removeEventListener('click', onClick);
		document.removeEventListener(pointerdownEvent, onPointerdown);
		scrolleventsManager.remove(onScroll);

		instance.el.addEventListener('click', preventDefault);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		// This is mainly used for debugging purposes
		uid++;
		instance.el.setAttribute('data-slider-id', uid.toString());

		settings = Object.assign({}, defaults, options);
		viewport = instance.ref(config.viewportRef);
		slider = instance.ref(config.sliderRef);
		paginationDots = instance.ref(config.paginationDotRef);
		setTouchProperties(instance.el);
		containerBounds = getDimensions(viewport);

		// if (settings.dragEnabled) {
		// 	instance.el.classList.add('drag-enabled');
		// }

		slideCollection = getSlides({
			el: instance.el,
			infiniteLoop: settings.infiniteLoop
		});

		setSlideWidths(slideCollection);
		slidesCount = slideCollection.length;

		if (settings.infiniteLoop && slidesCount > 1) {
			// 1. 'Rip' out all slides from DOM
			for (let i = slideCollection.length; i--; ) {
				try {
					slideCollection[i].el.parentNode.removeChild(slideCollection[i].el);
				} catch (e) {
					// It's ok. do nothing here.
				}
			}

			// // 2. Insert slides into wrapper
			updateSlidesWrapperFill(true);

			// Set slider offset
			setStyles(slider, {
				position: 'relative',
				left: (slidesCount * 100 * -1).toString() + '%'
			});
		}

		onResize();

		resizeeventsManager.add(onResize);
		pubsub.on('ProductOverview.opened', onResize);

		// Show the slider. (Avoids initial flickering when slides are being positioned)
		instance.el.classList.add(config.readyState);

		updateSlidePos(0);

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();

		resizeeventsManager.remove(onResize);
		pubsub.off('ProductOverview.opened', onResize);
	};

	return instance;
}
