/**
 * SectionQuad
 *
 * @selector [data-js="SectionQuad"]
 * @enabled true
 */
import { base } from 'app/util/base';

// const defaults = {};

const config = {
	optionsAttr: 'data-options',
	containerRef: 'container'
};

export default function SectionQuad() {
	// Private vars
	const instance = {};
	// let settings = {};
	let observer;
	let containers;

	// Private methods
	const handleIntersect = entries => {
		entries.forEach(entry => {
			if (entry.intersectionRatio >= 0.25) {
				observer.unobserve(entry.target);
				entry.target.classList.add('visible');
			}
		});
	};

	const bindEvents = () => {};
	const unbindEvents = () => {};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// // Get options from element. These will override default settings
		// let options = {};
		// if (instance.el.hasAttribute(config.optionsAttr)) {
		// 	options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		// }
		//
		// settings = Object.assign({}, defaults, options);
		containers = instance.ref(config.containerRef, true);
		observer = new window.IntersectionObserver(handleIntersect, { threshold: 0.25 });

		containers.forEach(c => {
			observer.observe(c);
		});

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
