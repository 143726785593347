/**
 * Navigation
 *
 * @selector [data-js="ProductOverlay"]
 * @enabled true
 */
import { base } from 'app/util/base';
import EventHandler from 'app/util/EventHandler';

export default function ProductOverlay() {
	// Private vars
	const instance = {};
	const eventHandler = new EventHandler();

	let openElements, closeElements;

	const open = event => {
		event.preventDefault();
		instance.el.classList.add('active');
		document.body.classList.add('ProductOverlayOpen');
	};

	const close = event => {
		event.preventDefault();
		instance.el.classList.add('hide');
	};

	const ended = event => {
		if (event.animationName === 'HideOverlay') {
			instance.el.classList.remove('active');
			instance.el.classList.remove('hide');
			document.body.classList.remove('ProductOverlayOpen');
		}
	};

	const bindEvents = () => {
		eventHandler.onSelector(document, 'click', `[data-ref="${instance.el.id}"]`, open);
		eventHandler.onSelector(instance.el, 'click', '.ProductOverlay--closeBtn, .ProductOverlay--background', close);
		eventHandler.on(instance.el, 'animationend', ended);
	};

	const unbindEvents = () => {
		eventHandler.off();
	};

	// Public vars
	instance.ns = 'ProductOverlay';

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		const img = instance.el.querySelector('img');
		if (img) {
			const loaded = () => img.classList.remove('loading');
			img.complete ? loaded() : (img.onload = loaded);
		}

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
