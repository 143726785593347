/**
 * Tooltip
 *
 * @selector [data-js="Tooltip"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { resizeeventsManager } from 'app/util/events-manager';

export default function Tooltip() {
	// Private vars
	const instance = {};
	let triggerNode;
	let contentNode;

	// Private methods

	const bindEvents = () => {
		resizeeventsManager.add(onResize);
		triggerNode.addEventListener('mouseenter', repositionTooltip);
	};

	const onResize = () => {
		repositionTooltip();
	};

	const repositionTooltip = () => {
		const div = contentNode;
		const element = triggerNode;

		const boundsDiv = div.getBoundingClientRect();

		const x = div.offsetWidth * -0.5 + element.offsetWidth / 2;
		const y = div.offsetHeight * -1 - element.offsetHeight / 2;

		div.maxWidth = 'none';
		div.style.left = x + 'px';
		div.style.top = y + 'px';

		if (boundsDiv.x < 0 || boundsDiv.x + boundsDiv.width > window.innerWidth) {
			const boundsElement = element.getBoundingClientRect();
			div.style.maxWidth = window.innerWidth + 'px';
			div.style.left = boundsElement.x * -1 + 'px';
		}
	};

	const unbindEvents = () => {
		resizeeventsManager.remove(onResize);
		triggerNode.removeEventListener('mouseenter', repositionTooltip);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		triggerNode = instance.ref('trigger');
		contentNode = instance.ref('content');

		bindEvents();
		repositionTooltip();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
