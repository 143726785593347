/**
 * Navigation
 *
 * @selector [data-js="ProductStickyHeader"]
 * @enabled true
 */
import { base } from 'app/util/base';
import fastdom from 'fastdom';

export default function StickyHeader() {
	// Private vars
	const instance = {};

	let links;

	function validate() {
		const { el } = instance;
		let elRect, activeId;

		fastdom.measure(function() {
			// get my scroll and dimension
			elRect = el.getBoundingClientRect();
			let activeDistance = Number.MAX_VALUE;
			const margin = Math.min(250, Math.max(50, window.innerHeight / 4));

			links.forEach(function(link) {
				// get chapter position
				link.chapterRect = link.chapterEl.getBoundingClientRect();

				// find the active chapter (if any)
				// we would go for elRect.bottom, but iOS 10 Safari returns
				// wrong values while scrolling, so we just assume the element sticks
				// on top
				const distance = elRect.height - link.chapterRect.top;
				if (distance >= -margin && distance <= activeDistance) {
					activeId = link.targetId;
					activeDistance = distance;
				}
			});
		});

		fastdom.mutate(function() {
			// activate the active link
			if (activeId !== links.activeId) {
				const disableEls = links.filter(el => el.targetId === links.activeId);
				const activeEls = links.filter(el => el.targetId === activeId);

				disableEls.forEach(disableEl => {
					disableEl.el.classList.remove('active');
				});
				activeEls.forEach(activeEl => {
					activeEl.el.classList.add('active');

					scrollLeftTo(activeEl.el, activeEl.el.parentNode.parentNode);
				});

				links.activeId = activeId;
			}
			if (elRect.top <= 0) {
				el.classList.remove('hide');
			} else {
				el.classList.add('hide');
			}
		});
	}

	const scrollTo = event => {
		event.preventDefault();
		const href = event.target.getAttribute('href');
		const anchorTarget = document.getElementById(href.substr(1));
		if (anchorTarget) {
			anchorTarget.scrollIntoView();
		}
	};

	const scrollLeftTo = (el, container) => {
		const xPos = el.offsetLeft - container.offsetLeft;
		container.scrollTo({
			top: 0,
			left: Math.max(xPos - container.offsetWidth / 2 + el.offsetWidth / 2, 0),
			behavior: 'smooth'
		});
	};

	const bindEvents = () => {
		document.addEventListener('scroll', validate, { passive: true });
		links.map(link => link.el.addEventListener('click', scrollTo));
	};

	const unbindEvents = () => {
		document.removeEventListener('scroll', validate, { passive: true });
		links.map(link => link.el.removeEventListener('click', scrollTo));
	};

	// Public vars
	instance.ns = 'StickyHeader';

	// Public methods
	instance.init = element => {
		instance.el = element;

		Object.assign(instance, base(instance));

		const stickyHeaderAnchors = [...instance.el.querySelectorAll('a.ProductStickyHeader--anchor')];
		links = stickyHeaderAnchors
			.map(anchor => {
				const targetId = anchor.getAttribute('href').replace('#', '');
				return {
					el: anchor,
					chapterEl: document.getElementById(targetId),
					targetId: targetId
				};
			})
			.filter(link => !!link.chapterEl);

		bindEvents();
		validate();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
