/**
 * Navigation
 *
 * @selector [data-js="ProductSplitHeader"]
 * @enabled true
 */
import { base } from 'app/util/base';
//import { pubsub } from 'app/modules/pubsub';

export default function SplitHeader() {
	// Private vars
	const intersectionOptions = {
		rootMargin: '0px',
		threshold: 0.4
	};
	const instance = {};
	let panel, observer;

	let callback = entries => {
		entries.forEach(entry => {
			if (entry.intersectionRatio >= intersectionOptions.threshold) {
				instance.el.classList.add('small');
			} else {
				if (entry.boundingClientRect.top > 0) {
					instance.el.classList.remove('small');
				}
			}
		});
	};

	function transitionStart(event) {
		if (event.target.classList.contains('ProductSplitHeader--left')) {
			instance.el.classList.add('hide-peeker');
		}
	}

	function transitionEnd(event) {
		if (event.target.classList.contains('ProductSplitHeader--left')) {
			window.dispatchEvent(new Event('resize'));
			instance.el.classList.remove('hide-peeker');
		}
	}

	const bindEvents = () => {
		observer.observe(panel);
		document.addEventListener('transitionend', transitionEnd);
		document.addEventListener('transitionstart', transitionStart);
	};

	const unbindEvents = () => {
		observer.unobserve(panel);
		document.removeEventListener('transitionend', transitionEnd);
		document.removeEventListener('transitionstart', transitionStart);
	};

	// Public vars
	instance.ns = 'SplitHeader';

	// Public methods
	instance.init = element => {
		instance.el = element;

		observer = new IntersectionObserver(callback, intersectionOptions);
		panel = instance.el.querySelector('.ProductSplitHeader--right');

		Object.assign(instance, base(instance));
		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
