import { animateProps } from 'app/util/animate';
import { easeOutCubic, easeOutQuad } from 'app/util/easings';

export const slide = ({ slides, currentIndex, newIndex, sliderWrapper, onComplete, isInfiniteLoop }) => {
	onComplete = onComplete || function() {};

	let startX = 0;
	let endX = 0;

	if (isInfiniteLoop) {
		endX = (newIndex - currentIndex) * 100;
	} else {
		// Get start x position
		for (let i = 0; i < currentIndex; i++) {
			startX += slides[i].widthPercent;
		}

		// Get end x position
		for (let i = 0; i < newIndex; i++) {
			endX += slides[i].widthPercent;
		}
	}

	animateProps({
		el: sliderWrapper,
		duration: 440,
		easing: easeOutCubic,
		props: [
			{
				propName: 'transform',
				start: startX * -1,
				end: endX * -1,
				prefix: 'translateX(',
				suffix: '%)'
			}
		],
		onComplete: onComplete.bind(null, ['transform'])
	});
};

/**
 * This transition works only if width of slide is 100%.
 *
 * @param slides
 * @param currentIndex
 * @param newIndex
 * @param bounds
 * @param onComplete
 */
export const overlap = ({ slides, currentIndex, newIndex, bounds, onComplete }) => {
	onComplete = onComplete || function() {};

	const newSlide = slides[newIndex].el;
	const currentSlide = slides[currentIndex].el;
	const direction = newIndex > currentIndex;
	const newSlideOffset = Math.abs(currentIndex - newIndex) - 1;

	// Set position for new slide
	newSlide.style.willChange = 'transform';
	currentSlide.style.willChange = 'transform';
	newSlide.style.zIndex = '1';

	animateProps({
		el: newSlide,
		props: [
			{
				propName: 'transform',
				prefix: 'translate3d(',
				suffix: 'px, 0, 0)',
				start: newSlideOffset * bounds.width * (direction ? -1 : 1),
				end: (newSlideOffset * bounds.width + bounds.width) * (direction ? -1 : 1)
			}
		],
		duration: 400,
		easing: easeOutCubic
	});

	animateProps({
		el: currentSlide,
		props: [
			{
				propName: 'transform',
				prefix: 'translate3d(',
				suffix: 'px, 0, 0)',
				start: 0,
				end: bounds.width * 0.33 * (direction ? -1 : 1)
			}
		],
		duration: 400,
		easing: easeOutQuad,
		onComplete: onComplete.bind(null, ['transform', 'zIndex', 'willChange'])
	});
};

export const fade = ({ slides, currentIndex, newIndex, bounds, onComplete }) => {
	onComplete = onComplete || function() {};

	const newSlide = slides[newIndex].el;
	let x = bounds.width * Math.abs(currentIndex - newIndex);
	x = newIndex > currentIndex ? -1 * x : x;

	newSlide.style.transform = `translateX(${x}px)`;
	newSlide.style.zIndex = '1';
	newSlide.style.opacity = '0';

	animateProps({
		el: newSlide,
		duration: 300,
		props: [
			{
				propName: 'opacity',
				start: 0,
				end: 1
			}
		],
		easing: easeOutCubic,
		onComplete: onComplete.bind(null, ['opacity', 'zIndex', 'transform'])
	});
};
