import contextTrigger from 'lib/util/contextTrigger';
import ModuleManager from 'lib/util/ModuleManager';



import Accordion from '01-widgets/Accordion/Accordion';



import AmountField from '01-widgets/AmountField/AmountField';



import ContentDrawer from '01-widgets/ContentDrawer/ContentDrawer';



import Dropdown from '01-widgets/Dropdown/Dropdown';



import Peeker from '01-widgets/Peeker/Peeker';



import Slider from '01-widgets/Slider/Slider';



import Smoothscroll from '01-widgets/Smoothscroll/Smoothscroll';



import Tooltip from '01-widgets/Tooltip/Tooltip';



import SectionQuad from '02-content/00-General/SectionQuad/SectionQuad';



import VideoSection from '02-content/00-General/VideoSection/VideoSection';



import VideoTeaser from '02-content/00-General/VideoTeaser/VideoTeaser';



import HomeExperience from '02-content/10-Home/HomeExperience/HomeExperience';



import ArticleTeasers from '02-content/Article/ArticleTeasers/ArticleTeasers';



import UpdateCart from '02-content/Checkout/Cart/UpdateCart';



import CheckoutSection from '02-content/Checkout/CheckoutSection/CheckoutSection';



import PaymentForm from '02-content/Checkout/PaymentForm/PaymentForm';



import PaymentFormStripe from '02-content/Checkout/PaymentForm/PaymentFormStripe';



import PaymentSelectionRadio from '02-content/Checkout/PaymentSelection/PaymentSelectionRadio';



import ContactForm from '02-content/Contact/ContactForm/ContactForm';



import DealerFilter from '02-content/Dealer/DealerHero/DealerFilter';



import DealerInfoList from '02-content/Dealer/DealerInfoList/DealerInfoList';



import DealerMap from '02-content/Dealer/DealerMap/DealerMap';



import ProductConfigurator from '02-content/Product/00-ProductConfigurator/ProductConfigurator';



import ProductHeroInfo from '02-content/Product/00-ProductHeroInfo/ProductHeroInfo';



import ProductSplitHeader from '02-content/Product/00-ProductSplitHeader/ProductSplitHeader';



import ProductStickyHeader from '02-content/Product/00-ProductStickyHeader/ProductStickyHeader';



import ProductOverview from '02-content/Product/10-ProductOverview/ProductOverview';



import ProductOverlay from '02-content/Product/20-ProductOverlay/ProductOverlay';



import CookieNotification from '03-global/CookieNotification/CookieNotification';



import InfoBanner from '03-global/InfoBanner/InfoBanner';



import Langselect from '03-global/Langselect/Langselect';



import Navigation from '03-global/Navigation/Navigation';



export default (function() {
	let time = new Date();


	contextTrigger.add('[data-js="Accordion"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Accordion));
	});


	contextTrigger.add('[data-js="AmountField"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(AmountField));
	});


	contextTrigger.add('[data-js="ContentDrawer"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ContentDrawer));
	});


	contextTrigger.add('[data-js="Dropdown"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Dropdown));
	});


	contextTrigger.add('[data-js="Peeker"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Peeker));
	});


	contextTrigger.add('[data-js="Slider"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Slider));
	});


	contextTrigger.add('[data-js="Smoothscroll"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Smoothscroll));
	});


	contextTrigger.add('[data-js="Tooltip"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Tooltip));
	});


	contextTrigger.add('[data-js="SectionQuad"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(SectionQuad));
	});


	contextTrigger.add('[data-js="VideoSection"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(VideoSection));
	});


	contextTrigger.add('[data-js="VideoTeaser"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(VideoTeaser));
	});


	contextTrigger.add('[data-js="HomeExperience"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(HomeExperience));
	});


	contextTrigger.add('[data-js="ArticleTeasers"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ArticleTeasers));
	});


	contextTrigger.add('[data-js-update="Cart"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(UpdateCart));
	});


	contextTrigger.add('[data-js="CheckoutSection"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CheckoutSection));
	});


	contextTrigger.add('[data-js="PaymentForm"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(PaymentForm));
	});


	contextTrigger.add('[data-js="PaymentFormStripe"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(PaymentFormStripe));
	});


	contextTrigger.add('input.PaymentSelection--payment--selection', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(PaymentSelectionRadio));
	});


	contextTrigger.add('[data-js="ContactForm"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ContactForm));
	});


	contextTrigger.add('[data-js="DealerFilter"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(DealerFilter));
	});


	contextTrigger.add('[data-js="DealerInfoList"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(DealerInfoList));
	});


	contextTrigger.add('[data-js="DealerMap"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(DealerMap));
	});


	contextTrigger.add('[data-js="ProductConfigurator"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ProductConfigurator));
	});


	contextTrigger.add('[data-js="ProductHeroInfo"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ProductHeroInfo));
	});


	contextTrigger.add('[data-js="ProductSplitHeader"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ProductSplitHeader));
	});


	contextTrigger.add('[data-js="ProductStickyHeader"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ProductStickyHeader));
	});


	contextTrigger.add('[data-js="ProductOverview"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ProductOverview));
	});


	contextTrigger.add('[data-js="ProductOverlay"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ProductOverlay));
	});


	contextTrigger.add('[data-js="CookieNotification"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CookieNotification));
	});


	contextTrigger.add('[data-js="InfoBanner"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(InfoBanner));
	});


	contextTrigger.add('[data-js="Langselect"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Langselect));
	});


	contextTrigger.add('[data-js="Navigation"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Navigation));
	});



	contextTrigger.validate(document.body);

	console.log('Selecting components took: ', new Date() - time, 'ms');
}());
