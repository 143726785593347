/**
 * Cart
 *
 * @selector input.PaymentSelection--payment--selection
 * @enabled true
 */

export default function Cart() {
	// Private vars
	const instance = {};

	// Private methods

	const focus = () =>
		requestAnimationFrame(() => {
			try {
				instance.el.scrollIntoView({ behavior: 'smooth', block: 'start' });
			} catch (e) {
				instance.el.scrollIntoViewIfNeeded();
			}
		});

	const bindEvents = () => {
		instance.el.addEventListener('click', focus);
	};

	const unbindEvents = () => {
		instance.el.removeEventListener('click', focus);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		bindEvents();
		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
