/**
 * Convert a DOM string to actual DOM object.
 * The desired DOM must only have one parent.
 *
 * @param domString
 * @returns {Node}
 */
export const str2dom = function(domString) {
	if (typeof domString !== 'string') {
		throw new Error('This method only accepts a string.');
	}

	const el = document.createElement('div');
	el.innerHTML = domString;
	return el.children[0].cloneNode(true);
};
