/**
 * PaymentFormStripe
 *
 * @selector [data-js="PaymentFormStripe"]
 * @enabled true
 */
import { base } from 'app/util/base';

export default function PaymentFormStripe() {
	// Private vars
	const instance = {};
	let formEl;

	const stripe = Stripe('pk_live_51ILOI3GcQHB1Lh5Ar72BhTypJ9OXfBOIyPnQlInqh6BeG5Qe71oKmW9f7suMGT1wqbyBLynHUrP9I0EZSZscx5f700hJJt3ZB1');
	let stripeElements;
	let cardNumberObj;

	// Private methods

	const initStripe = () => {
        var baseurl = window.location.origin === "https://staging.riposa.ch" ? "https://staging.riposa.ch" : "https://riposa.ch";
        stripeElements = stripe.elements({
            fonts: [
                {
                    family: 'Work Sans',
                    src: 'url(' + baseurl + '/assets/fonts/work-sans-v5-latin-ext_latin-300.woff)',
                    weight: '300',
                    display: 'swap',
                    style: 'normal'
                },
            ]
        });
	};
	const activateStripe = () => {
        const style = {
            base: {
                fontFamily: '"Work Sans",Helvetica Neue,Helvetica,sans-serif',
                fontWeight: 300,
                fontSize: '22px',
                lineHeight: '39px',
                fontSmoothing: 'antialiased',

                color: '#0f0f0e',
                iconColor: '#0f0f0e'
            },

            invalid: {
                //iconColor: 'rgb(252, 98, 98)',
                //color: 'rgb(252, 98, 98)',
            },
        };


		const elementClasses = {
			focus: 'has-focus',
			empty: 'is-empty',
			invalid: 'has-error'
		};

        let cardNumber = stripeElements.create('cardNumber', {
            style: style,
			classes: elementClasses
        });
        cardNumber.mount('#strp-card-number');

        let cardExpiry = stripeElements.create('cardExpiry', {
            style: style,
			classes: elementClasses
        });
        cardExpiry.mount('#strp-card-expiry');

        let cardCvc = stripeElements.create('cardCvc', {
            style: style,
			classes: elementClasses
        });
        cardCvc.mount('#strp-card-cvc');


        cardNumberObj = cardNumber;

        console.log(stripe);
	};



	const getFormValue = (name) => {
		return document.querySelector("form[name='"+ name +"']");
	};


	const getPaymentData = () => {
		return {
			name: getFormValue("NAME-CARD-HOLDER"),
	        billing_details: {
	            email: getFormValue("orderEmail")
	        }
	    };
	};


	const onFormSubmit = (event) => {
	    event.preventDefault();

	    var paymentData = getPaymentData();

	    stripe.createPaymentMethod('card', cardNumberObj, paymentData).then(function(result) {
	        if (result.error) {
	            // Show the user any errors
	            var errorElement = document.getElementById('card-errors');
	            errorElement.textContent = result.error.message;
	        } else {
	            // Insert the token ID into the form so it gets submitted to the server
	            var form = document.getElementById('payment-form');
	            var hiddenInput = document.createElement('input');
	            hiddenInput.setAttribute('type', 'hidden');
	            hiddenInput.setAttribute('name', 'paymentMethodId'); // Craft Commerce only needs this
	            hiddenInput.setAttribute('value', result.paymentMethod.id);
	            form.appendChild(hiddenInput);

	            form.submit();
	        }
	    });
	};

    const bindEvents = () => {
    	formEl.addEventListener('submit', onFormSubmit);
    };


	const unbindEvents = () => {
    	formEl.removeEventListener('submit', onFormSubmit);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		formEl = instance.ref('form');
		console.log(formEl);
		initStripe();
        activateStripe();
		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
