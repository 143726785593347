/**
 * Dropdown
 *
 * @selector [data-js="Dropdown"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { animateProps } from 'app/util/animate';
import { easeOutCubic } from 'app/util/easings';
import { setStyles, clearStyles } from 'app/util/dom-helpers';
import { pointerdownEvent } from 'app/util/pointer-event-names';

// const defaults = {};

const config = {
	optionsAttr: 'data-options',
	triggerRef: 'trigger',
	listWrapperRef: 'listWrapper',
	itemRef: 'listItem',
	overlayRef: 'overlay',
	openBodyClass: 'dropdown-open'
};

export default function Dropdown() {
	// Private vars
	const instance = {};
	// let settings = {};
	let trigger;
	let listWrapper;
	let listHeight;
	let listItems;
	let overlay;

	// Private methods
	const open = () => {
		instance.setState('opening');

		unbindEvents();

		document.body.classList.add(config.openBodyClass);

		// Get list height
		setStyles(listWrapper, { display: 'block' });
		listHeight = listWrapper.getBoundingClientRect().height;
		clearStyles(listWrapper, ['display']);

		// Prepare for animation
		setStyles(listWrapper, {
			display: 'block',
			height: '0'
		});

		setStyles(listItems, { opacity: '0' });

		// Animate height
		animateProps({
			el: listWrapper,
			duration: 300,
			delay: 150,
			easing: easeOutCubic,
			props: [
				{
					propName: 'height',
					start: 0,
					end: listHeight,
					suffix: 'px'
				}
			]
		});

		// Animate items
		animateProps({
			el: listItems,
			duration: 300,
			delay: 300,
			stagger: 50,
			easing: easeOutCubic,
			props: [
				{
					propName: 'opacity',
					start: 0.7,
					end: 1
				}
			],
			onComplete: () => {
				instance.setState('open');
				clearStyles([listWrapper, ...listItems], ['opacity', 'display', 'height']);
				bindEvents();
			}
		});
	};

	const close = () => {
		instance.setState('closing');
		unbindEvents();

		document.body.classList.remove(config.openBodyClass);

		// Get list height
		listHeight = listWrapper.getBoundingClientRect().height;

		animateProps({
			el: listItems.reverse(),
			duration: 200,
			stagger: 50,
			easing: easeOutCubic,
			props: [
				{
					propName: 'opacity',
					start: 1,
					end: 0
				}
			]
		});

		animateProps({
			el: listWrapper,
			duration: 200,
			delay: 50 * (listItems.length - 1) + 200,
			easing: easeOutCubic,
			props: [
				{
					propName: 'height',
					start: listHeight,
					end: 0,
					suffix: 'px'
				}
			],
			onComplete: () => {
				instance.setState('closed');
				clearStyles([listWrapper, ...listItems], ['opacity', 'display', 'height']);
				listItems.reverse();
				bindEvents();
			}
		});
	};

	const toggle = () => {
		if (instance.getState() === 'open') {
			close();
		} else {
			open();
		}
	};

	const onPointerdown = () => {
		toggle();
	};

	const bindEvents = () => {
		trigger.addEventListener(pointerdownEvent, onPointerdown);
	};

	const unbindEvents = () => {
		trigger.removeEventListener(pointerdownEvent, onPointerdown);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		// let options = {};
		// if (instance.el.hasAttribute(config.optionsAttr)) {
		// 	options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		// }

		// settings = Object.assign({}, defaults, options);
		trigger = instance.ref(config.triggerRef);
		listWrapper = instance.ref(config.listWrapperRef);
		listItems = instance.ref(config.itemRef);
		overlay = instance.ref(config.overlayRef);

		overlay = document.body.appendChild(overlay);

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
