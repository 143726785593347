export const smoother = function(callback) {
	if (callback === undefined || typeof callback !== 'function') {
		throw new Error('No callback function has been passed.');
	}

	let isRendering = false;

	return function() {
		if (!isRendering) {
			isRendering = true;

			requestAnimationFrame(() => {
				callback(...arguments);

				isRendering = false;
			});
		}
	};
};
