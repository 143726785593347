/**
 * AmountField
 *
 * @selector [data-js="AmountField"]
 * @enabled true
 */
import { base } from 'app/util/base';

export default function AmountField() {
	// Private vars
	const instance = {};
    let decrementBtn;
    let incrementBtn;
    let amountField;
    let amountFieldMin;
    let amountFieldMax;
    let amountFieldStep;

	// Private methods

    const getInputVal = () => {
        return parseInt(amountField.value);
    };

    const updateInput = (val) => {
        let fireEvent = parseInt(amountField.value) !== parseInt(val);

        amountField.value = val;

        var event = new Event('input', {
            bubbles: true,
            cancelable: true,
        });

        fireEvent && amountField.dispatchEvent(event);
    };

    const updateBtns = () => {
        const val = getInputVal();
        decrementBtn.disabled = val <= amountFieldMin;
        incrementBtn.disabled = val >= amountFieldMax;
    };

    const correctAmount = (amount) => {
        amount = Math.max(amountFieldMin, amount);
        amount = Math.min(amountFieldMax, amount);

        if(isNaN(amount)) {
            amount = 1;
        }

        return amount;
    };

    const onDecrementBtn = () => {
        let newVal = getInputVal() - amountFieldStep;
        newVal = correctAmount(newVal);
        updateInput(newVal);
        updateBtns();
    };

    const onIncrementBtn = () => {
        let newVal = getInputVal() + amountFieldStep;
        newVal = correctAmount(newVal);

        updateInput(newVal);
        updateBtns();
    };

    const onChangeAmount = () => {
        let newVal = getInputVal();
        newVal = correctAmount(newVal);

        updateInput(newVal);
    };

	const bindEvents = () => {
		decrementBtn.addEventListener('click', onDecrementBtn);
        incrementBtn.addEventListener('click', onIncrementBtn);
        amountField.addEventListener('input', onChangeAmount);

	};

	const unbindEvents = () => {
		decrementBtn.removeEventListener('click', onDecrementBtn);
        incrementBtn.removeEventListener('click', onIncrementBtn);
        amountField.removeEventListener('input', onChangeAmount);

	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

        decrementBtn = instance.ref('decrementBtn');
        incrementBtn = instance.ref('incrementBtn');
        amountField = instance.ref('amountField');

        amountFieldMin = parseInt(amountField.min) || - Infinity;
        amountFieldMax = parseInt(amountField.max) || Infinity;
        amountFieldStep = parseInt(amountField.step) || 1;

		bindEvents();
        updateBtns();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
