class EventHandler {
	constructor() {
		this.listeners = []
	}

	on(targetEl, type, handler, options = false) {
		let namespace = null
		try {
			if (type.indexOf(".") > 0) {
				const [extractedType, extractedNamespace] = type.split(".")
				namespace = extractedNamespace
				type = extractedType
			}
			targetEl.addEventListener(type, handler, options)
			const listener = new Listener({
				target: targetEl,
				type,
				handler,
				options,
				namespace,
			})
			this.listeners.push(listener)
			return listener
		} catch (e) {
			return false
		}
	}

	onSelector(targetEl, type, selector, handler, options = false) {
		return this.on(
			targetEl,
			type,
			(event) => {
				const currentTarget = eventMatches(event, selector, targetEl)
				if (currentTarget) {
					event.delegateTarget = currentTarget
					handler(event)
				}
			},
			options
		)
	}

	currentTarget(event) {
		return event.delegateTarget || event.currentTarget
	}

	off() {
		this.listeners.forEach((listener) => listener.unlisten())
		this.listeners = []
	}

	offByFilter(filterFn) {
		let count = 0
		for (let i = this.listeners.length - 1; i >= 0; i--) {
			const listener = this.listeners[i]
			if (filterFn(listener)) {
				listener.unlisten()
				this.listeners.splice(i, 1)
				count++
			}
		}
		return count
	}

	offByNamespace(namespace) {
		return this.offByFilter((listener) => listener.namespace === namespace)
	}

	offByTarget(targetEl) {
		return this.offByFilter((listener) => listener.target === targetEl)
	}

	offByListener(listener) {
		return this.offByFilter((l) => l === listener)
	}

	destroy() {
		this.off()
		this.el = null
	}
}

function unlisten({ target, type, handler, options }) {
	try {
		target.removeEventListener(type, handler, options)
	} catch (e) {
		// ignore for now
	}
}

class Listener {
	constructor({ target, type, handler, options, namespace }) {
		this.target = target
		this.type = type
		this.handler = handler
		this.options = options
		this.namespace = namespace
	}

	unlisten() {
		unlisten(this)
	}
}

function eventMatches(event, selector, container) {
	let el = event.target
	if (!(el instanceof Element)) {
		return
	}
	while (el) {
		if (
			(
				el.matches ||
				el.matchesSelector ||
				el.msMatchesSelector ||
				el.mozMatchesSelector ||
				el.webkitMatchesSelector ||
				el.oMatchesSelector
			).call(el, selector)
		) {
			return el
		}
		el = el !== container && el.parentElement
	}
	return false
}

export default EventHandler

export const eventHandler = new EventHandler()
