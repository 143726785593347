/**
 * DealerFilter
 *
 * @selector [data-js="DealerFilter"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { pubsub } from 'app/modules/pubsub';

const defaults = {
	notFoundMessage: 'not found'
};

const config = {
	optionsAttr: 'data-options',
	nameRef: 'dealerName',
	categorySelectRef: 'dealerCategory',
	locationRef: 'dealerLocation'
};

export default function DealerFilter() {
	// Private vars
	const instance = {};
	let settings = {};
	let categorySelect;
	let nameInput;
	let locationInput;
	let locationComponent;
	let lastSearch = {
		locationOrZip: '',
		text: '',
		category: 'all'
	};

	// Private methods
	const onSubmit = e => {
		e.preventDefault();

		lastSearch = {
			locationOrZip: locationInput.value,
			text: nameInput.value,
			category: categorySelect.value === 'all' ? 'all' : Number(categorySelect.value)
		};

		pubsub.trigger('DealerFilter.filter', lastSearch);
	};

	const onNotFound = () => {
		// Set error style on search input
		locationComponent.classList.add('has-error');
	};

	const onSearch = () => {
		// Remove error style on search input
		locationComponent.classList.remove('has-error');
	};

	const onIdle = () => {
		// Trigger initial preselected filter
		if (!categorySelect.querySelectorAll('option')[0].selected) {
			let submitEvent = new Event('submit');
			onSubmit(submitEvent);
		}
	};

	const bindEvents = () => {
		instance.el.addEventListener('submit', onSubmit);
		categorySelect.addEventListener('change', onSubmit);
		nameInput.addEventListener('change', onSubmit);

		pubsub.on('DealerMap.notFound', onNotFound);
		pubsub.on('DealerMap.search', onSearch);
		pubsub.on('DealerMap.idle', onIdle);
	};

	const unbindEvents = () => {
		instance.el.removeEventListener('submit', onSubmit);
		categorySelect.removeEventListener('change', onSubmit);
		nameInput.removeEventListener('change', onSubmit);

		pubsub.off('DealerMap.notFound', onNotFound);
		pubsub.off('DealerMap.search', onSearch);
		pubsub.off('DealerMap.idle', onIdle);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		categorySelect = instance.ref(config.categorySelectRef);
		nameInput = instance.ref(config.nameRef);
		locationInput = instance.ref(config.locationRef);
		locationComponent = locationInput.closest('[data-js="Input"]');

		// Set error message to input
		locationComponent.querySelector('[data-ref="Input:error"]').innerHTML = settings.notFoundMessage;

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
