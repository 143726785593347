import 'promise-polyfill/src/polyfill';
import 'mdn-polyfills/Object.assign';
import 'mdn-polyfills/Element.prototype.closest';
import 'mdn-polyfills/Array.from';
import 'mdn-polyfills/NodeList.prototype.forEach';
import 'mdn-polyfills/Node.prototype.prepend';
import 'mdn-polyfills/Node.prototype.remove';
import 'mdn-polyfills/Array.prototype.includes';
import 'mdn-polyfills/String.prototype.endsWith';
import 'mdn-polyfills/Array.prototype.find';
import 'intersection-observer';
import 'es6-symbol/implement';

// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
// Test via a getter in the options object to see if the passive property is accessed
window.HV = window.HV || {};
window.HV.supportsPassive = false;
try {
	let opts = Object.defineProperty({}, 'passive', {
		get: function() {
			window.HV.supportsPassive = true;
			return true;
		}
	});
	window.addEventListener('testPassive', null, opts);
	window.removeEventListener('testPassive', null, opts);
} catch (e) {
	// No support or passive listeners. sad
}
