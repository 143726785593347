/**
 * ArticleTeasers
 *
 * @selector [data-js="ArticleTeasers"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { animateValue } from 'app/util/animate';
import { easeInOutCubic } from 'app/util/easings';

const defaults = {
	nextUrl: '',
	responseDelay: 500 // ms
};

const config = {
	optionsAttr: 'data-options',
	loadMoreRef: 'loadMore',
	containerRef: 'container',
	ctaContainerRef: 'ctaContainer',
	anchorTpl: pageNr => `
	<div id="article-teasers-page-${pageNr}" class="ArticleTeasers--anchor"></div>
	`
};

export default function ArticleTeasers() {
	// Private vars
	const instance = {};
	let settings = {};
	let loadMore;
	let container;
	let ctaContainer;
	let pageNr = 1;
	let currentNextUrl;

	// Private methods
	const updateLoadMore = () => {
		if (!currentNextUrl) {
			ctaContainer.remove();
			return;
		}

		loadMore.href = currentNextUrl;
	};

	const onLoadMore = e => {
		e.preventDefault();

		instance.setState('loading');

		let xhr = new XMLHttpRequest();
		xhr.open('get', loadMore.href);
		xhr.setRequestHeader('Content-Type', 'text/html; charset=UTF-8');
		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
		xhr.onload = () => {
			setTimeout(() => {
				if (xhr.status === 200) {
					let result = JSON.parse(xhr.responseText);

					pageNr++;
					currentNextUrl = result.nextUrl;

					let newContent = config.anchorTpl(pageNr);
					newContent += result.html;

					container.insertAdjacentHTML('beforeend', newContent);

					let anchor = document.getElementById('article-teasers-page-' + pageNr);

					updateLoadMore();
					instance.setState('');

					// Scroll to added teasers
					let scrollY = window.scrollY || window.pageYOffset;
					let targetPos = anchor.getBoundingClientRect().top + scrollY;

					animateValue({
						duration: 400,
						start: scrollY,
						end: targetPos,
						easing: easeInOutCubic,
						onUpdate: data => {
							window.scrollTo(0, data);
						}
					});
				} else {
					// an error happened...
				}
			}, settings.responseDelay);
		};

		xhr.send();
	};

	const bindEvents = () => {
		loadMore.addEventListener('click', onLoadMore);
	};
	const unbindEvents = () => {
		loadMore.removeEventListener('click', onLoadMore);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		container = instance.ref(config.containerRef);
		ctaContainer = instance.ref(config.ctaContainerRef);
		loadMore = instance.ref(config.loadMoreRef);

		if (loadMore && settings.nextUrl) {
			currentNextUrl = settings.nextUrl;
			updateLoadMore();
			bindEvents();
		}

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
