/**
 * PaymentForm
 *
 * @selector [data-js="PaymentForm"]
 * @enabled true
 */
import { base } from 'app/util/base';

export default function PaymentForm() {
	// Private vars
	const instance = {};
	let agbCheckboxNode;
	let submitBtnNode;
	let formAGBaccepted = false;
	let paymenttype;

	// Private methods




	const trackPaymentMethod = () => {
		window.dataLayer.push({
			event: 'eec.checkoutOption',
			ecommerce: {
				checkout_option: {
					actionField: {
						step: 4,
						option: paymenttype //'Kreditkarte' /* Kreditkarte | Vorkasse | Rechnung */
					}
				}
			}
		});
	};


	const onFormSubmit = (event) => {
	    if(!formAGBaccepted) {
	    	event.preventDefault();
	    	return;
	    }
	    trackPaymentMethod();
	};

	const onCheckboxChange = (event) => {
		formAGBaccepted = agbCheckboxNode.checked;
		if (submitBtnNode && submitBtnNode.tagName === 'BUTTON') {
			submitBtnNode.disabled = !formAGBaccepted;
		} else {
			submitBtnNode.classList.toggle('disabled', !formAGBaccepted);
		}
	};

    const bindEvents = () => {
    	agbCheckboxNode.addEventListener('change', onCheckboxChange);
    	instance.el.addEventListener('submit', onFormSubmit);
    };


	const unbindEvents = () => {
    	agbCheckboxNode.removeEventListener('change', onCheckboxChange);
    	instance.el.removeEventListener('submit', onFormSubmit);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		agbCheckboxNode = instance.ref('accept-agb') || document.querySelector('input[name="accept-agb-twint"]');
		submitBtnNode = instance.ref('submit') || document.querySelector('form[data-paymenttype="twint"]');

		onCheckboxChange();

		bindEvents();
		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
