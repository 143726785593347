/**
 * ProductOverview
 *
 * @selector [data-js="ProductOverview"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { clearStyles, setStyles } from 'app/util/dom-helpers';
import { animateProps, animateValue } from 'app/util/animate';
import { easeInOutCubic, easeOutCubic } from 'app/util/easings';
import { getScrollY } from 'app/util/scroll-info';
import { resizeeventsManager } from 'app/util/events-manager';
import { pubsub } from 'app/modules/pubsub';
import { height as vpHeight } from 'app/util/viewport';

// const defaults = {};

const config = {
	optionsAttr: 'data-options',
	teaserRef: 'teaser',
	drawerRef: 'drawer',
	teaserWrapperRef: 'teaserWrapper',
	drawerItemRef: 'drawerItem'
};

export default function ProductOverview() {
	// Private vars
	const instance = {};
	// let settings = {};
	let drawers;
	let activeTeaser;
	let teaserWrappers;
	let topY;

	// Private methods
	const scrollDrawerIntoView = (el, height) => {
		let rect = el.getBoundingClientRect();
		if (height > vpHeight()) {
			height = vpHeight();
		}

		if (rect.bottom + height > vpHeight()) {
			let scrollY = getScrollY();
			animateValue({
				duration: 300,
				start: scrollY,
				end: scrollY + (rect.bottom + height - vpHeight()),
				easing: easeInOutCubic,
				onUpdate: data => {
					window.scrollTo(0, data);
				}
			});
		}
	};

	const open = index => {
		let tmpTopY = teaserWrappers[index].getBoundingClientRect().top + getScrollY();
		let heightAnim = true;
		let drawerOrder;

		const openDrawer = (thereWereOpenDrawers = false, previousOpenDrawerTopY = false) => {
			let delay = 150;
			let drawer = drawers[index];
			let drawerItems = drawer.querySelectorAll(instance.refSelector(config.drawerItemRef));

			drawer.setAttribute('data-state', 'opening');
			teaserWrappers[index].setAttribute('data-state', 'opening');

			// 1. Get drawer height
			setStyles(drawer, { display: 'flex' });
			let height = drawer.getBoundingClientRect().height;
			clearStyles(drawer, ['display']);

			if (heightAnim) {
				// 2. Prepare for animation
				setStyles(drawer, {
					display: 'flex',
					order: drawerOrder.toString(),
					height: '0'
				});

				setStyles(drawerItems, { opacity: 0 });

				// 3. Animate height
				animateProps({
					el: drawer,
					duration: 300,
					easing: easeOutCubic,
					props: [
						{
							propName: 'height',
							start: 0,
							end: height,
							suffix: 'px'
						}
					],
					onStart: () => {
						teaserWrappers[index]
							.querySelector(instance.refSelector(config.teaserRef))
							.setAttribute('data-state', 'opening');
					}
				});
			} else {
				delay = 0;
				setStyles(drawer, {
					display: 'flex',
					order: drawerOrder.toString()
				});
			}

			setStyles(drawerItems, {
				opacity: 0
			});

			animateProps({
				el: drawerItems,
				duration: 300,
				delay: delay,
				stagger: 50,
				easing: easeOutCubic,
				props: [
					{
						propName: 'opacity',
						start: 0,
						end: 1
					}
				],
				onComplete: () => {
					clearStyles([drawer, ...drawerItems], ['height', 'opacity', 'display']);
					drawer.setAttribute('data-state', 'open');
					teaserWrappers[index].setAttribute('data-state', 'open');
					teaserWrappers[index]
						.querySelector(instance.refSelector(config.teaserRef))
						.setAttribute('data-state', 'active');
					activeTeaser = teaserWrappers[index];
					unBlock();

					// Trigger layout updates of components inside of drawer (like Slider).¨
					window.requestAnimationFrame(() => {
						pubsub.trigger('ProductOverview.opened');
					});
				}
			});

			// Scroll drawer into view if neccessary
			let tmp;
			// console.log('thereWereOpenDrawers:', thereWereOpenDrawers)
			// console.log('previousOpenDrawerTopY:', previousOpenDrawerTopY)
			// console.log('drawer.getBoundingClientRect().top', drawer.getBoundingClientRect().top)
			if (previousOpenDrawerTopY) {
				tmp = thereWereOpenDrawers && previousOpenDrawerTopY > drawer.getBoundingClientRect().top ? height : 0;
			} else {
				tmp = height;
			}
			scrollDrawerIntoView(teaserWrappers[index], tmp);
			// scrollDrawerIntoView(teaserWrappers[index], height);
		};

		// Check if there's an open drawer
		// First get topY position of currently open drawer
		topY = activeTeaser ? activeTeaser.getBoundingClientRect().top + getScrollY() : -1;

		// Get the order nr for the drawer
		for (let i = index; i < teaserWrappers.length; i++) {
			if (teaserWrappers[i].getBoundingClientRect().top + getScrollY() > tmpTopY) {
				drawerOrder = i;

				break;
			}
		}

		if (drawerOrder === undefined) {
			drawerOrder = teaserWrappers.length;
		}

		if (topY >= 0) {
			if (tmpTopY === topY) {
				// We're on the same row
				// Close/open without height animation
				heightAnim = false;

				close({
					heightAnimation: heightAnim,
					doUnblock: false,
					onComplete: () => {
						openDrawer();
					}
				});
			} else {
				// Close any active/open drawer
				close({
					heightAnimation: true,
					doUnblock: false
				});

				// Open drawer simultaneously with close animation
				openDrawer(true, topY);
			}

			return;
		}

		// No drawer is currently open. yay
		openDrawer();
	};

	const close = ({ index, heightAnimation = true, doUnblock = true, onComplete }) => {
		const onCloseEnd = drawer => {
			drawer.setAttribute('data-state', 'closed');
			teaserWrappers[index].setAttribute('data-state', 'closed');
			teaserWrappers[index].querySelector(instance.refSelector(config.teaserRef)).setAttribute('data-state', '');
			clearStyles(drawer, ['opacity', 'order', 'height']);
			activeTeaser = null;

			onComplete && onComplete();
			doUnblock && unBlock();
		};

		const closeDrawer = drawer => {
			if (!heightAnimation) {
				animateProps({
					el: drawer,
					duration: 200,
					easing: easeOutCubic,
					props: [
						{
							propName: 'opacity',
							start: 1,
							end: 0
						}
					],
					onComplete: () => {
						onCloseEnd(drawer);
					}
				});

				return;
			}

			let height = drawer.getBoundingClientRect().height;

			animateProps({
				el: drawer,
				duration: 200,
				easing: easeOutCubic,
				props: [
					{
						propName: 'height',
						start: height,
						end: 0,
						suffix: 'px'
					}
				],
				onComplete: () => {
					onCloseEnd(drawer);
				}
			});
		};

		// block();
		if (index === undefined || index === null) {
			for (let i = drawers.length; i--; ) {
				if (drawers[i].getAttribute('data-state') === 'open') {
					index = i;
					closeDrawer(drawers[index]);
				}
			}
		} else {
			closeDrawer(drawers[index]);
		}
	};

	const toggle = index => {
		if (drawers[index].getAttribute('data-state') === 'open') {
			close(index);
		} else {
			open(index);
		}
	};

	const onClick = e => {
		if (e.target.closest(instance.refSelector(config.teaserRef))) {
			e.preventDefault();

			const index = e.target.closest(instance.refSelector(config.teaserWrapperRef)).getAttribute('data-index');

			block();
			toggle(index);
		}
	};

	const preventDefault = e => {
		e.preventDefault();
		e.stopPropagation();
	};

	const block = () => {
		instance.el.removeEventListener('click', onClick);
		instance.el.addEventListener('click', preventDefault);
	};

	const unBlock = () => {
		instance.el.removeEventListener('click', preventDefault);
		instance.el.addEventListener('click', onClick);
	};

	const onResize = () => {
		if (activeTeaser) {
			let order;
			let currentIndex = Number(activeTeaser.getAttribute('data-index'));
			let tmpTopY = teaserWrappers[currentIndex].getBoundingClientRect().top + getScrollY();

			clearStyles(drawers[currentIndex], ['order']);
			setStyles(drawers[currentIndex], { display: 'none' });

			for (let i = currentIndex; i < teaserWrappers.length; i++) {
				if (teaserWrappers[i].getBoundingClientRect().top + getScrollY() > tmpTopY) {
					order = i;

					break;
				}
			}

			if (order === undefined) {
				order = teaserWrappers.length;
			}

			setStyles(drawers[currentIndex], {
				order: order
			});
			clearStyles(drawers[currentIndex], ['display']);
		}
	};

	const bindEvents = () => {
		instance.el.addEventListener('click', onClick);
		resizeeventsManager.add(onResize);
	};

	const unbindEvents = () => {
		instance.el.removeEventListener('click', onClick);
		resizeeventsManager.remove(onResize);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// // Get options from element. These will override default settings
		// let options = {};
		// if (instance.el.hasAttribute(config.optionsAttr)) {
		// 	options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		// }
		//
		// settings = Object.assign({}, defaults, options);
		teaserWrappers = instance.ref(config.teaserWrapperRef, true);
		drawers = instance.ref(config.drawerRef, true);

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
